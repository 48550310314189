import { Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 
import { ModalManager } from 'ngb-modal';
import {NgForm} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
@Component({ 
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'] 
})

export class UsersComponent implements OnInit {
  public users:any[]=[];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  public survey:any = []; 
  filedata:any;
  dtTrigger: Subject<any> = new Subject();
  
  public id_colaboradores:any = 0;
  private modalRef; 
  public msjerror:any;
  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService,private modalService: ModalManager) { }
 
    @ViewChild('ModalUpload', {static: true}) ModalUpload;
    public rolUser:string = '';

    fileEvent(e){
      this.filedata = e.target.files[0];
    }
    
  ngOnInit() {
    let user =  this.serv.traerDataUsuario(); 
    if(typeof user.user == 'undefined'){
      this.router.navigate(['/']); 
    }else{ 
      if( user.user.role!='root'){
      
        this.msjerror = 'No tiene privilegiós para realizar esta acción';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });

    }


   }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 20
    };
    this.serv.getListUser( (e)=>{  
      this.users = e.dataUsers; 
      this.dtTrigger.next();
    }); 
    this.rolUser = this.serv.traerDataUsuario().user.role;
  }

  addUser(){ 
  if(this.rolUser == 'root'){
    this.router.navigate(["/registro-owner"]);
  }else if(this.rolUser == 'owner'){
    this.router.navigate(["/registro-empleado"]);
  }else{

    alert('No tiene privilegios para realizar esta acción');
    
  }
}
  openModalUploadUsers(){
    this.modalRef = this.modalService.open(this.ModalUpload, {
        size: "md",
        modalClass: 'ModalUpload',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true, 
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
    })
}

closeModal(){
  this.id_colaboradores = 0;
  this.survey = ''; 
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
}

onSubmitform(f: NgForm) {  
  var myFormData = new FormData();

    
  myFormData.append('document', this.filedata, this.filedata.name);
  myFormData.append('userid', this.serv.traerDataUsuario().user.id);

 this.serv.uploadDataUsers(myFormData); 
 this.closeModal();

} 
ngOnDestroy(): void {
  // Do not forget to unsubscribe the event
  this.dtTrigger.unsubscribe();
}

}
