import { Component, OnInit, KeyValueDiffers, ViewChild } from '@angular/core';
import { VgAPI } from 'videogular2/compiled/core';

import { ServiService } from '../../servicios/servi.service';

import { ActivatedRoute,Router } from '@angular/router';
import { ModalManager } from 'ngb-modal'; 
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-reprovideo',
  templateUrl: './reprovideo.component.html',
  styleUrls: ['./reprovideo.component.scss']
})
export class ReprovideoComponent implements OnInit {
  @ViewChild('myModal', {static: true}) myModal;
  public viendoPreguntas=false;
  public viendoQuiz = false;
  public viendoVideoError = false;
  public arrow=false;
  preload: string = 'auto';
  api: VgAPI;
  public tituloCapitulo: string = "";
  public previousTime = 0;
  public currentTime = 0;
  public seekStart = null;
  public capitulos: any[] = [] 
  public viendoTemp: any;
  public viendoTempMedalla: any;
  public materiales: any[] = [];
  public urlmateriales: any[] = [];
  public ultimaGuardada = 0; 
  public porcentaje: any;
  public viendoCualCap: number;
  public capituloActual;
  private modalRef;
  public completedVideo = 0;
  public sesionHash:string = '';
  public sessionBottler: string = "";
  public bottler:any;
  public asd = '';
  public url_user:string = '';
  public data_user_load:any;

  private playerTime;
  private respondioQuiz: boolean = false;

  constructor(public serv: ServiService, public router: Router,private modalService: ModalManager,private metaService: Meta) {
 

  }

  onPlayerReady(api: VgAPI) {
    this.api = api;//https://videogular.github.io/videogular2/docs/getting-started/using-the-api.html
    // this.api.getDefaultMedia().currentTime = 11.621817;// Set the video to the beginning

    this.api.getDefaultMedia().subscriptions.ended
      .subscribe(() => {

        if (this.viendoVideoError) {
          this.viendoVideoError = false;
          this.continuarConCapitulo(this.playerTime, false);
          this.viendoQuiz = true;
        }else{
   
          if (this.capitulos[this.capitulos.length - 1].i != this.capituloActual.i) {
            //QUEDAN CAPS POR VER DE ESTA TEMPORADA
         
            this.viendoPreguntas=false;

              if(this.viendoTemp == this.serv.progreso.t && this.serv.progreso.c<=this.capituloActual.i){
                    this.serv.progreso.c++
                    this.serv.progreso.tiempo = 0
                    this.serv.progreso.porcentaje = 0
                    alert("¡Felicitaciones! Finalizaste el "+this.tituloCapitulo+".<br>¡Te esperamos en el siguiente!"); 
              }
          
          
             this.abrirCapNext(window["temporadas"][(this.viendoTemp - 1)].capitulos[this.capituloActual.i+1]);
 
            } else {
              //FIN TEMPORADA
            
              this.quitarVideoYPonerPreguntasDeLaTemporada(this.viendoTemp)
    


            }

            if(this.viendoTemp == this.serv.progreso.t){
            this.serv.setVideo(this.viendoTemp + "-" + this.capituloActual.i, this.currentTime, 100, 1);
            }
        }



      });

    this.api.getDefaultMedia().subscriptions.playing
      .subscribe(() => {
        //    console.log('playing');
      });

    this.api.getDefaultMedia().subscriptions.play
      .subscribe(() => {
        //   console.log('play');
      });

    this.api.getDefaultMedia().subscriptions.pause
      .subscribe(() => {
        //  console.log('pause');
      });

    this.api.getDefaultMedia().subscriptions.abort
      .subscribe(() => {
        // console.log('abort');
      });

    this.api.getDefaultMedia().subscriptions.progress
      .subscribe(() => {
   
     
      });

    this.api.getDefaultMedia().subscriptions.seeked
      .subscribe(() => {
        //  console.log('seeked');

      });

    this.api.getDefaultMedia().subscriptions.seeking
      .subscribe(() => {

    //    console.log('seeking',this.serv.progreso);
          if(this.seekStart === null) {
            this.seekStart = this.previousTime;
          }
          if( this.serv.progreso.t==this.viendoTemp && this.serv.progreso.c==this.capituloActual.i){
         
            if(this.api.getDefaultMedia().currentTime > this.serv.progreso.tiempo) {
       
              this.api.getDefaultMedia().currentTime = this.previousTime;
            }
          }
      
    
        this.seekStart = null;
      });

    this.api.getDefaultMedia().subscriptions.suspend
      .subscribe(() => {
        //  console.log('suspend');
      });


    this.api.getDefaultMedia().subscriptions.timeUpdate
      .subscribe(() => {
        //  console.log('time update');
       // console.log(this.capituloActual);
        if (!this.api.getDefaultMedia()["seeking"] && !this.viendoVideoError) {

          let time = this.api.getDefaultMedia().currentTime;
          if( !(this.capituloActual["preguntas"] === undefined )) {

            let preguntasCapitulo = this.capituloActual.preguntas;
            let tiempoPregunta = preguntasCapitulo.tiempo;

          //  console.log(Math.trunc(time) + " ---- " +Math.trunc(tiempoPregunta))
            if ((( time>= tiempoPregunta-0.125 && time<= tiempoPregunta+0.125) && !this.respondioQuiz) ) {
              this.currentTime = time;
              this.api.getDefaultMedia().pause();
              this.viendoQuiz = true;
            }
        }
        let materialesMostrar = window["temporadas"][(this.viendoTemp - 1)].mostrarmaterial;

        if( !(materialesMostrar === undefined )) {
            for(var i=0;i<materialesMostrar.length;i++){
                  let tiempoMostrar = materialesMostrar[i].tiempo;
            
                      if(materialesMostrar[i].capitulo==this.capituloActual.i){
                        if ((( time>= tiempoMostrar-0.125 && time<= tiempoMostrar+0.125)) ) {
                          if(!this.arrow){
                            this.playAudio();
                            document.getElementById('arrow-materiales').style.display="block";
                            this.arrow=true;
                            setTimeout(() => {
                              document.getElementById('arrow-materiales').style.display="none";
                              this.arrow=false;
                            }, 6000);
                          }
                           
                        }  
                    }
                  }
          }
          this.porcentaje = (this.api.getDefaultMedia().currentTime * 100 / this.api.getDefaultMedia().duration).toFixed(2)
          if (isNaN(this.porcentaje)) this.porcentaje = 0;
          this.currentTime = this.api.getDefaultMedia().currentTime
          //  this.serv.establecerTiempoMax(this.currentTime);
          this.guardarTiempoEnApi();
        }
      });

  }

  nombreMaterial(mat:any){
    let cachos=mat.split("|")
    let nom= cachos[cachos.length-1]
    nom=nom.replace("1- KM","").replace(".pptx","").replace(".xlsx","").replace("2 -X1- KM","").replace("6- KM","").replace("2 -X","")
    return nom
  }



abrirMaterial(mat:any){
  var url = mat.split('| '); 

  if (typeof url[0] !== 'undefined') {
    
    window.open(url[0],'_blank'); 
 
    let name_document = url[1];
    let chapter = this.capituloActual.i;
    let seasons = this.serv.viendoTemporada;
    let user_id = this.serv.traerDataUsuario().user.id;

    this.serv.downloadDocumentUsersSaved(name_document, chapter,seasons, user_id,(e)=>{             
     let obj = e;
     console.log(e)
    });

    
  }else{
    console.log('ok 2');  
    console.log(mat);
    let url_document = mat.split('.'); 
    let name_document = url_document[0];
    let chapter = this.capituloActual.i;
    let seasons = this.serv.viendoTemporada;
    let user_id = this.serv.traerDataUsuario().user.id;

    this.serv.downloadDocumentUsersSaved(name_document, chapter,seasons, user_id,(e)=>{             
     let obj = e;
     console.log(e)
    });
    window.open(mat,'_blank'); 
  } 

  



/*
  let a=document.createElement("a");
  a["src"]=mat;
  a["download"]=mat;
  a.click();
*/
  }

  materialIfbottler(mat:any){
    console.log('materialIfbottler new');
    var url = mat.split('| '); 
console.log('blank')
    if (typeof url[0] !== 'undefined') {
      console.log('ok maif 1');
      window.open(url[0],'_blank'); 
    }else{
      console.log('ok maif 2');
      window.open(mat,'_blank'); 
    } 
    
  }

  guardarTiempoEnApi() {
 
  
    if (this.serv.vioCapitulo(this.viendoTemp, this.capituloActual.i) > this.currentTime) {
  //    console.log("no guarda porq habia llegado mas lejos ya");
      return;
    }
    if ((Date.now() - this.ultimaGuardada) > 5000) {
      let completo;
      if (this.porcentaje > 99) completo = 1;
  
      this.serv.setVideo(this.viendoTemp + "-" + this.capituloActual.i, this.currentTime, this.porcentaje, completo);
      this.ultimaGuardada = Date.now();
     
    } else {




    }
  }

  quitarVideoYPonerPreguntasDeLaTemporada(t:number){
    ////CHEQUEAR SI TERMINO TODO

    if (this.serv.vioTodaLaTemporadaSegunSuRol(this.viendoTemp)) {
      alert("¡Felicitaciones! Haz finalizado la temporada. Te invitamos a responder el test y ganar tu medalla.");
            
      let vid = document.getElementById("singleVideo");
      vid["pause"]();
    this.viendoPreguntas=true;
    window["temporadas"][t-1].preguntas;
    this.tituloCapitulo="Test de respuesta múltiple, elije la que consideres correcta";
    }else{
      alert("Para poder hacer el test es necesario ver todos los capítulos de la temporada")
    }
  }

  durationChange(e?: any) {


  }
  abrirPreguntas(){
    }
  ngOnInit() {
    this.data_user_load = this.serv.getProfileUserLoad(this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e; 

      if (typeof localStorage.bottler != null) {
        if(localStorage.bottler == 1){
         console.log('gepp bottler');
        } 
      }
    

      var temporal = JSON.parse(localStorage.getItem('usuario'));
      var cola = obj.body.user.collaborators;
      
      if(cola != null && cola.length > 0 ){ 
      for(let i=0;i<cola.length;i++){
       // if(cola[i].user.id==this.id_colaboradores){
       //   cola.splice(i,1);
       cola[i].user.status = cola[i].user.status; 
        //  break;
     //   }
      }
      
      temporal.user.collaborators = cola; 
      localStorage.setItem('usuario',JSON.stringify(temporal));
    }


      });
    
    this.url_user = this.serv.traerDataUsuario().user.url_user;
    if(typeof localStorage.hash != 'undefined'){
      this.sesionHash =  localStorage.hash;
    }
    if (typeof localStorage.bottler != null) {
      this.sessionBottler = localStorage.bottler;
    }
    
    window["vplayer"] = this
    this.viendoTemp = this.serv.viendoTemporada;

    this.viendoTempMedalla = this.viendoTemp;

    if(this.viendoTempMedalla == 1){ 
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_cobre.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );
  }else if(this.viendoTempMedalla == 2){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_bronce.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else if(this.viendoTempMedalla == 3){

    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_plata.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );
  }else if(this.viendoTempMedalla == 4){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_oro.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else if(this.viendoTempMedalla == 5){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_platino.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else{
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_platino.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }
  



    this.capitulos = this.serv.traerTodosLosCapitulosQTieneQHaberVistoPorTemporada(this.viendoTemp);

    this.materiales = window["temporadas"][(this.viendoTemp - 1)].materiales;

   /* for (let i in this.materiales) {
      var url = this.materiales[i].split('| '); 
      console.log(url); 
        if (typeof url[0] !== 'undefined') {
          this.urlmateriales.push(url[0]);
        }else{ 
          this.urlmateriales.push(url);
        } 
      

    
    }*/

    for(let material of this.materiales){

      var url = material.src.split('| '); 
      console.log(material+' material'); 
        if (typeof url[0] !== 'undefined') {
          console.log(url[0]+'ok 1'); 
          this.urlmateriales.push(url[0]);
          
        }else{
          this.urlmateriales.push(material.src);
          console.log(material.src+' url de los materiales each href sisa');
           
        } 
  
    }

    this.materiales['urlmat'] = this.urlmateriales;

    console.log(this.urlmateriales);

   




    if(this.completedVideo == 1){
      this.openModal();
    }



  }
  respondioTest() { 

    this.serv.viendoTemporada++;
  // console.log()
  /*  if (this.serv.viendoTemporada > 5) {
      this.router.navigate(['/colaboradores'], { state: { msj: 'Felicidades, has completado todas las temporadas.', status: '1' } });
    }*/
        if(this.viendoTemp == this.serv.progreso.t){
      

          this.serv.progreso.c = 0;
          this.serv.progreso.tiempo = 0;
          this.serv.progreso.porcentaje = 0;
    }

    let vioEsteCapitulo = this.serv.vioCapitulo(this.serv.viendoTemporada, 0);


    this.completedVideo = 1;
  

    this.capituloActual = window["temporadas"][this.serv.viendoTemporada-1].capitulos[0];


    let vid = document.getElementById("singleVideo");

    vid["src"] = this.serv.urlVideos + this.capituloActual.videoURL
    this.tituloCapitulo = this.capituloActual.titulo

    this.serv.establecerCapitulo(this.capituloActual);


    this.viendoPreguntas=false;
    this.viendoQuiz=false;
    this.ngOnInit();
  }
  abrirCapituloEnQEstaba() {
    let cap;
    let caps = this.serv.traerTodosLosCapitulosQTieneQHaberVistoPorTemporada(this.viendoTemp)
    if(this.serv.viendoTemporada>=this.serv.progreso.t)
    {

        

        for (let i = 0; i < caps.length; i++) {

          
          let vioEsteCapitulo = this.serv.vioCapitulo(this.viendoTemp, caps[i].i);
          if (vioEsteCapitulo != -1) {

            //si no es -1 ni 1 es q estaba en este capitulo y el ser devuelve el tiempo
    
            this.abrirCap(caps[i]);
        

          }
        }
    }else{
   
      this.capituloActual=caps[0];
      this.abrirCap(caps[0]);
    }

  }

  switchearSubSeccion() {
    window["$"](".paneles").toggleClass("off");
    window["$"]("#reprovideo .nav li").toggleClass("active");
  }
  abrirCap(k: any) {
    this.viendoPreguntas=false;
    let vioEsteCapitulo = this.serv.vioCapitulo(this.viendoTemp, k.i);
    
    if (k.i > 0) {
      if (vioEsteCapitulo == -1) {
        alert("Recuerda que debes terminar el capítulo anterior para acceder a los siguientes");
        return
      }
      if (!this.serv.vioTodaLaTemporadaSegunSuRol(this.viendoTemp - 1)) return "no viste la temporada anterior completa"
    }

    this.capituloActual = k;

 

    let vid = document.getElementById("singleVideo");

    vid["src"] = this.serv.urlVideos + k.videoURL
    this.tituloCapitulo = k.titulo

    this.serv.establecerCapitulo(k);

    if (vioEsteCapitulo != 1 && vioEsteCapitulo != -1) {
      //si no es -1 ni 1 es q estaba en este capitulo y el ser devuelve el tiempo

      setTimeout(() => {
        vid["play"]()
        this.currentTime = this.api.getDefaultMedia().currentTime = vioEsteCapitulo;
      }, 1500);
    } else {
      setTimeout(() => {
        vid["play"]()
      }, 1600);

    }

  }
  abrirCapNext(k: any) {


    let vioEsteCapitulo = this.serv.vioCapitulo(this.viendoTemp, k.i);
    if (k.i > 0) {
      //no es el primer capitulo de la temporada:
      if (vioEsteCapitulo == -1) {
       alert("Recuerda que debes terminar el capítulo anterior para acceder a los siguientes");
        return
      }
      if (!this.serv.vioTodaLaTemporadaSegunSuRol(this.viendoTemp - 1)) return "no viste la temporada anterior completa"
    }

    this.capituloActual = k;


    let vid = document.getElementById("singleVideo");
    vid["src"] = this.serv.urlVideos + k.videoURL
    this.tituloCapitulo = k.titulo

    this.serv.establecerCapitulo(k);

    if (vioEsteCapitulo != 1 && vioEsteCapitulo != -1) {
      //si no es -1 ni 1 es q estaba en este capitulo y el ser devuelve el tiempo

      setTimeout(() => {
        this.currentTime = this.api.getDefaultMedia().currentTime = vioEsteCapitulo;
      }, 1500);
    } 

  }

  ask(){

  }

  cargarVideoError(url:string, time: number){
    this.playerTime = time;
    this.respondioQuiz = true;
    this.viendoVideoError = true;

    let vid = document.getElementById("singleVideo");
    vid["src"] = this.serv.urlVideos + url;
    this.viendoQuiz = false;
    setTimeout(() => {
      vid["play"]()
    }, 1600);
  }

  continuarConCapitulo(time:number, play: boolean = true){
    let vid = document.getElementById("singleVideo");
    if(vid["src"].includes(this.capituloActual.videoURL)){
     // setTimeout(() => {
        this.api.getDefaultMedia().play();
    //  }, 1200);
    }else{
      vid["src"] = this.serv.urlVideos + this.capituloActual.videoURL;
    //  setTimeout(() => {
        vid["play"]();
        this.api.getDefaultMedia().pause();
        this.api.getDefaultMedia().currentTime = time;
        if (play) {
          this.api.getDefaultMedia().play(); 
        }        
     // }, 1600);
    }

    this.respondioQuiz = true;
    this.viendoQuiz = false;

   

  }



  ngAfterViewInit() {
    document.addEventListener("visibilitychange", () => {
      this.api.getDefaultMedia().pause()

    }, false)

    document.addEventListener("blur", () => {
      this.api.getDefaultMedia().pause()

    }, false)



    this.abrirCapituloEnQEstaba()
  }

  openModal(){
    this.modalRef = this.modalService.open(this.myModal, {
        size: "md",
        modalClass: 'mymodal',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
    })
} 
closeModal(){
    this.modalService.close(this.modalRef);  
    if (this.serv.viendoTemporada > 5) {
      this.router.navigate(['/temporadas'], { state: { msj: 'Felicidades, has completado todas las temporadas.', status: '1' } });
    }
    //or this.modalRef.close();
}

logout(){
  this.serv.logout();
  this.router.navigate(["/login"]);
  }

  urlUser(url_user){
    document.location.href = url_user;
  }

  playAudio(){
    let audio = new Audio();
    audio.src = "../../../assets/material.mp3";
    audio.load();
    audio.play();
  }
  
}
