import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import '../datas/temporadas.js';
@Injectable({
  providedIn: 'root'
})
export class ServiService {
  public urlVideos = "assets/videos/";
  urlFbServ: string = 'No url ...';


  //public urlImg = 'https://api.3kmpepsi.com/api/v1/img-profile';

  ///public urlServer = "https://api3km.test/api/v1";
  // public urlServer = "https://api.3kmpepsi.com/api/v1";
  //public urlServer = "http://api3km.local/api/v1";
  ///public urlImg = 'http://api3km.local/api/v1/img-profile';
  //public urlServer = "https://api-3kmpepsi-com.wspuat.com/api/v1";
  //public urlImg = 'https://api-3kmpepsi-com.wspuat.com/api/v1/img-profile';
  public urlServer = "https://api.3kmpepsi.com/api/v1";
  public urlImg = '  https://api.3kmpepsi.com/api/v1/img-profile';

   public progreso: any = {
    t: 1, //temporada
    c:0, //capitulo
    tiempo: 0.1, //segundos
    porcentaje:0
  }

  public viendoTemporada: number;
  public viendoCapitulo: number;
  constructor(public http: HttpClient,
    public route: Router) {
    window["serv"] = this;
    if (this.tokenOK()) this.actualizarUsuarioConLaApi();
  }

  deleteUser(id:any){

    let url = this.urlServer + "/deleteUser";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());


    let data = {
      id: id
    }

    // console.log(data)


    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()

  }

  resetPassConfirm(hash:any, password:any,confirm_password:any ,cb: Function){
    let url = this.urlServer +  "/reset-pass-confirm";

    let  data = {
      new_password: password,
      confirm_password: confirm_password,
      verification_token: hash,
    }
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json');
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {
      cb(e)
     }).catch(e => console.log(e))


  }

  downloadDocumentUsersSaved(name_document:any, chapter:any,season:any, user_id:any ,cb: Function){
    let url = this.urlServer +  "/dowload-document";

    let  data = {
      name_document: name_document,
      chapter : chapter,
      season : season,
      user_id : user_id,
    }
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json');
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {
      cb(e)
     }).catch(e => console.log(e))


  }

  resetPassword(email:any ,cb: Function) {

    let url = this.urlServer +  "/reset-pass";

    let  data = {
      email: email,

    }
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json');
    this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {
      cb(e)
     }).catch(e => console.log(e))

  }

  traerDataUsuario() {
    if (localStorage["usuario"] == undefined || localStorage["usuario"] == "" || localStorage["usuario"] == null)     {
        localStorage["usuario"] = "{}";
      }
    return JSON.parse(localStorage["usuario"]);
  }



  saveQuestion(question:any ,cb: Function){

    let url = this.urlServer +  "/users/saveQuestionUser"

    if(typeof question == undefined || typeof question == 'undefined'){
     let  data = {
        question: 0,
        temp: this.progreso.t

      }
      let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
      this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {

       }).catch(e => console.log(e))

    }else{
     let  data = {
        question: question,
        temp: this.progreso.t

      }
      let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
      this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {

       }).catch(e => console.log(e))
    }




  }

  /* login2(user:any,pass:any): Observable<any> {
     const params = JSON.stringify({
       username:user,
       password: pass,
     });

     const headers = new HttpHeaders().set('Content-Type', 'application/json');
     return this.http.post(this.urlServer + '/authenticate', params, { headers: headers, observe: "response" });
   }*/

   verifyToken(token: string, cb: Function) {

    let url = this.urlServer + "/verify/"+token;
     let result = this.http.get(url,{ responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  generateCertificate(id, name, lastName,cb: Function) {

    let url = this.urlServer + "/generate-pdf?name="+name+"&lastName="+lastName;
      window.open(url,'_blank');
    if (typeof localStorage.bottler != null) {
      if(localStorage.bottler == 1){
        window.open(url,'_blank');
      }
    }

    let urlServ = this.urlServer +  "/saved-donload-cert";

    let  data = {
      user_id: id,

    }
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json');
    this.http.post(urlServ, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {
      cb(e)
     }).catch(e => console.log(e))


  }


  generateLogin(token: string, cb: Function) {

    let url = this.urlServer + "/generatelogin/"+token;
     let result = this.http.get(url,{ responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  getSurvey(cb: Function) {

    let url = this.urlServer + "/getSurvey";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  getNotice(cb: Function) {

    let url = this.urlServer + "/getNotice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }


  getCategories(cb: Function) {
    let url = this.urlServer + "/getCategories";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));
  }



  sendMailUser(id_col:any,id_user:any){

    let url = this.urlServer + "/sendMailUser";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());


    let data = {
      id_col: id_col,
      id_user: id_user,
    }

    // console.log(data)


    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()

  }


  getProfileUserLoad(user_id:any){
    let url = this.urlServer + "/users/getProfileUserLoad";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let data = {
      user_id: user_id,
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()
  }

  getNotify(user_id:any){
    let url = this.urlServer + "/get-notify";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let data = {
      user_id: user_id,
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()
  }

  notifySaved(cant_notice_notify:any,user_id:any){
    let url = this.urlServer + "/saved-notify";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let data = {
      cant_notice_notify:cant_notice_notify,
      user_id: user_id,
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()
  }

  savedNoticeView(notice_id:any,podcast_id:any,video_id:any,user_id:any){
    let url = this.urlServer + "/saved-notice-view";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let data = {
      notice_id:notice_id,
      podcast_id:podcast_id,
      video_id:video_id,
      user_id: user_id,
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()
  }


  getDetailNotice(id:any) {



    let url = this.urlServer + "/detail-notice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());


    let data = {
      id: id,
    }

    // console.log(data)


    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()

  }


  OldsendMailUser(id_col:any,id_user:any){
    let data = {
      id_col: id_col,
      id_user: id_user,
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/sendMailUser", data, {
    headers: headers
    }).subscribe(data => {
      let obj : any = data;
      let user =  this.traerDataUsuario();
      user.user.img_user = obj.data;
    //  console.log(user.user.collaborators);

      for (let i in user.user.collaborators) {
        if(user.user.collaborators[i].user.id == 252){
          console.log(user.user.collaborators[i].user.id);
          console.log(user.user.collaborators[i].user.status);
          user.user.collaborators[i].user.status = 'active';

        }

      }
      localStorage.setItem('usuario',JSON.stringify(user));
      console.log("user",user);
      this.route.onSameUrlNavigation = 'reload';
      this.route.navigate(["/colaboradores"]);
    return alert(obj.msg);
    });
  }


  uploadImageProfile(myFormData) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/uploadImageProfile", myFormData, {
    headers: headers
    }).subscribe(data => {
      let obj : any = data;
      let user =  this.traerDataUsuario();
      user.user.img_user = obj.data;
      localStorage.setItem('usuario',JSON.stringify(user));
      console.log("user",user);
     this.route.navigate(["/colaboradores"]);
    return alert(obj.msg);
    });

   /*  let url = this.urlServer + "/users/uploadImageProfile";
     let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
     headers.append('Content-Type', 'multipart/form-data');
     let data = {
        myFormData
     }

     this.http.post(url, myFormData, { headers: headers }).subscribe(data => {
     console.log(data);
     });
   */
  }

  savedNotice(myFormData) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/saved-notice", myFormData, {
    headers: headers
    }).subscribe(data => {
      console.log(data)

     this.route.navigate(["/colaboradores"]);
     alert('Noticia creada');

    },
    (err) => {
      this.route.navigate(["/colaboradores"]);
      alert('Ops, ocurrió un error');
      console.log(err)});


  }


  savedCategorie(myFormData) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/saved-categorie", myFormData, {
    headers: headers
    }).subscribe(data => {
      console.log(data)

     this.route.navigate(["/colaboradores"]);
     alert('Categoria creada');

    },
    (err) => {
      this.route.navigate(["/colaboradores"]);
      alert('Ops, ocurrió un error');
      console.log(err)});


  }

  updatedNotice(myFormData) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/updated-notice", myFormData, {
    headers: headers
    }).subscribe(data => {


    // this.route.navigate(["/colaboradores"]);
    this.route.navigate(["/colaboradores"]);
    alert('Noticia actualizada');

    },
    (err) => {
      this.route.navigate(["/colaboradores"]);
      alert('Ops, ocurrió un error');
      console.log(err)});
  }


  updatedCategorie(myFormData) {

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    this.http.post(this.urlServer + "/updated-categorie", myFormData, {
    headers: headers
    }).subscribe(data => {


    // this.route.navigate(["/colaboradores"]);
    this.route.navigate(["/colaboradores"]);
    alert('Categoria actualizada');

    },
    (err) => {
      this.route.navigate(["/colaboradores"]);
      alert('Ops, ocurrió un error');
      console.log(err)});
  }


  getCountrys(cb: Function) {

    let url = this.urlServer + "/getCountrys";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  getListUser(cb: Function) {

    let url = this.urlServer + "/list-users";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  getListNotice(cb: Function) {

    let url = this.urlServer + "/list-notice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }

  getListNoticeHome(cb: Function) {

    let url = this.urlServer + "/list-notice-home";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));


  }






  getRestaurant(id:any,cb: Function) {

    let url = this.urlServer + "/getRestaurantList";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let  data = {
      id: id,

    }
     this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {
      cb(e)
     }).catch(e => console.log(e))



  }

  getCitys(cb: Function) {

    let url = this.urlServer + "/getCitys";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    this.http.get(url,{ headers: headers, responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));

  }

  getGender(cb: Function) {

    let url = this.urlServer + "/getGender";
    this.http.get(url,{ responseType: 'json' })
     .toPromise().then(res => cb(res))
     .catch(err =>  cb(err));

  }

  getListOccupation(cb: Function) {

    let url = this.urlServer + "/getListOccupation";
    this.http.get(url,{ responseType: 'json' })
    .toPromise().then(res => cb(res))
    .catch(err =>  cb(err));


  }



  login(user: string, pass: string, cb: Function) {
    let url = this.urlServer + "/authenticate";
    let msj = '';


    window["$"]["ajax"]({
      url: url,
      dataType: "json",
      type: "post",
      success: (e) => {

        this.guardarUsuarioEnLS(e)
        cb(e)
      },
      data: {
        "username": user,
        "password": pass
      },
      error: e => cb('error')
    })
  }

  loginUrl(user: string, pass: string, cb: Function) {
    let url = this.urlServer + "/authenticate-user-url";
    let msj = '';


    window["$"]["ajax"]({
      url: url,
      dataType: "json",
      type: "post",
      success: (e) => {

        this.guardarUsuarioEnLS(e)
        cb(e)
      },
      data: {
        "username": user,
        "password": pass
      },
      error: e => cb('error')
    })
  }

  establecerCapitulo(k: number) {
    //local
    if (!this.tokenOK()) return false;
    this.viendoCapitulo = k;

  }


  guardarUsuarioEnLS(us: any) {
    this.progreso = {
      t: 1, //temporada
      c: 0, //capitulo
      tiempo: 0.1, //segundos
      porcentaje:0
    }

    localStorage["usuario"] = JSON.stringify(us);
    this.actualizarProgreso(us.user.profiles.videos);

    return us;
  }


  traerColaboradores() {
    if (!this.tokenOK()) return false;
    return this.traerDataUsuario().user.collaborators


  }
  rol() {
    if (!this.tokenOK()) return false;
    return this.traerDataUsuario().user.role

  }

  url_user() {
    if (!this.tokenOK()) return false;
    return this.traerDataUsuario().user.url_user

  }

  username() {
    if (!this.tokenOK()) return false;
    return this.traerDataUsuario().user.username
  }


  token() {
    if (!this.tokenOK()) return false;
    return this.traerDataUsuario().access_token

  }

  logout() {
    localStorage.clear();
  }


  parseJwt(token) {
    var objRta = {}


    try {
      var base64Url = token.split('.')[1];
      //  console.log(base64Url)
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      // console.log(base64)
      let bb = window.atob(base64);
      //console.log(bb);
      objRta = JSON.parse(bb);
    } catch (e) {
      // console.log(e)

    }
    return objRta;
  }
  tokenOK() {
    try {
      if (this.parseJwt(this.traerDataUsuario().access_token)["iat"] > 0) return true
      else return false
    } catch (e) {
      return false;
    } finally { }

  }

  setVideo(capitulo: any, tiempo: any, porcentaje?: number, completo?: any) {
   //if(isNaN(capitulo)) debugger;
    if (!this.tokenOK()) return false;

    if (completo == undefined) completo = 0;
    //guarda en la db lo q ya se vio
    let url = this.urlServer + "/users/setVideo";

    if (porcentaje == undefined) porcentaje = 0;
    let data = window["data"] = {
      "code": capitulo,
      "current_time": String(tiempo),
      "percentage": String(porcentaje),
      "complete": completo
    };

    this.actualizarProgreso([data]);


    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {

    }).catch(e => console.log(e))



    this.setSeason( Number(capitulo.split("-")[0]))
    // this.actualizarUsuarioConLaApi()

  }




  actualizarUsuarioConLaApi() {
    if (!this.tokenOK()) return false;
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let url = this.urlServer + "/users/getProfile"


    this.http.post(url, {}, { headers: headers, observe: "response" }).toPromise().then(e => {

      let us = JSON.parse(localStorage["usuario"])

      us.user.profiles.seasons = e.body["data"]["seasons"];
      us.user.profiles.videos = e.body["data"]["videos"];
      us.user.profiles.levels = e.body["data"]["levels"];
      localStorage["usuario"] = JSON.stringify(us);

      this.actualizarProgreso(this.traerDataUsuario().user.profiles.videos);

    }).catch(e => console.log(e))




  }

  actualizarProgreso(videos: any[]) {

    //console.log(videos)

    for (let i = 0; i < videos.length; i++) {
      let v = videos[i];
      let cualquiercosa = v.code.split("-");
      let temp = Number(cualquiercosa[0]);
      let cap = Number(cualquiercosa[1]);

      let tiempo = v.current_time
      let porcentaje = v.percentage


      if (temp > parseFloat(this.progreso.t)) {
        this.progreso.t = temp;
        this.progreso.c = cap;
        this.progreso.tiempo = tiempo;
        this.progreso.porcentaje = porcentaje;

      } else if (temp == parseFloat(this.progreso.t)) {

        if (cap > parseFloat(this.progreso.c)) {
          this.progreso.c = cap;
          this.progreso.tiempo = tiempo;
          this.progreso.porcentaje = porcentaje;
        } else if (cap == parseFloat(this.progreso.c)) {
         // console.log("mismo capitulo", tiempo , this.progreso.tiempo)
          if (tiempo > parseFloat(this.progreso.tiempo)) {

          //  console.log("tiempo mayor")
            this.progreso.tiempo = tiempo;
            this.progreso.porcentaje = porcentaje;
            if(porcentaje>99){
              /// LO MARCA COMO VISTO Y PONE Q ESTAS EN EL CAPITULO SIGUIENTE:

            }
          }
        }
      }
    }
    if(this.viendoTemporada>=this.progreso.t){
      this.establecerTemporada(this.progreso.t);
    }


  }


  calcularPorcentajeTemporada(){
    let t=this.progreso.t
    let c=this.progreso.c
    let p=this.progreso.porcentaje

    let totalTiempo=0;
    let totalTiempoVisto=0;

    for(let t=1;t<5;t++){
      let temp=this.traerTodosLosCapitulosQTieneQHaberVistoPorTemporada(t);
     // console.log(temp)
      for(let i=0;i<temp.length;i++) {
          totalTiempo+=temp[i].duracion;
          let vioCap=this.vioCapitulo(t,i);
          if(vioCap==1){
            totalTiempoVisto+=temp[i].duracion;
          }else if(vioCap>1){
            totalTiempo+=vioCap;
          }
      }

    }


      return totalTiempoVisto *100/ totalTiempo

   }

  setSeason(temporada: number) {
      if (!this.tokenOK()) return false;

      //guarda en la db lo q ya se vio
      let url = this.urlServer + "/users/setSeason";

      let porcentaje=this.calcularPorcentajeTemporada()
      let comple=0;
      if(porcentaje>99) comple=1;
      let data = window["data"] = {
        "code": temporada,
        "percentage": String(porcentaje),
        "complete": comple
      };



      let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
       this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise().then(e => {

        }).catch(e => console.log(e))



  }

  establecerTemporada(k: number) {
    if (!this.tokenOK()) return false;
    this.viendoTemporada = k;
  }
  /*
    establecerColaboradorActivo(k: any) {
      if (!this.tokenOK()) return false;
      if (localStorage["usuario"] == undefined || localStorage["usuario"] == "" || localStorage["usuario"] == null) localStorage["usuario"] = "{}";
      let us = JSON.parse(localStorage["usuario"]);
      us["colaboradorActivo"] = k
      localStorage["usuario"] = JSON.stringify(us);
      return us;
    }

    colaboradorActivo() {
      if (!this.tokenOK()) return false;
      if (localStorage["usuario"] == undefined || localStorage["usuario"] == "" || localStorage["usuario"] == null) localStorage["usuario"] = "{}";
      let us = JSON.parse(localStorage["usuario"]);
      return us["colaboradorActivo"]

    }*/



  vioCapitulo(t, c) {
    //el capitulo empieza de 0 en este metodo

    if (!this.tokenOK()) return false;

    if(c==0){
      //si es el primer capitulo de una temporada
      //la temporada anterior.. por eso -1

      if(this.vioTodaLaTemporadaSegunSuRol(t)) return 0.1;
    }


    if (t == this.progreso.t && (c == this.progreso.c)  ){
      if( this.progreso.porcentaje>95 ){
          return 1;
        }else {
          return this.progreso.tiempo;
        }

       }

    if (t < this.progreso.t) {
      return 1;
    } else if (t > this.progreso.t) {


      return -1;
    } else if (t == this.progreso.t) { //MISMA TEMPORADA
      //c+1 porq array
      if (c < this.progreso.c) { //el capitulo es anterior al q estabas viendo
        return 1;
      } else if(c ==this.progreso.c){ //es el q le sigue
        //el capitulo q le sigue
        if(this.progreso.porcentaje>99) return 0.1;
        else return -1
      }else if ((c + 1) > this.progreso.c) { //es mayor
        return -1
      }
    }
  }

  traerTodosLosCapitulosQTieneQHaberVistoPorTemporada(t){
    let te=window["temporadas"][t-1]
    let capsQTieneQHaberVisto=[];

    for(let i=0;i<te.capitulos.length;i++){
      let c=te.capitulos[i];
      if(c.hasOwnProperty("rol")){
        if(c.rol=="admin" && (this.rol()=="owner" || this.rol()=="root" )){
          //capitulos especiales
          capsQTieneQHaberVisto.push(c)
          }
        }else{
          capsQTieneQHaberVisto.push(c)

        }
     }

     return capsQTieneQHaberVisto;
  }

  vioTodaLaTemporadaSegunSuRol(t:number){

  if(this.progreso.t>t) return 1;
  else if(this.progreso.t<t) return 0;

    let te=window["temporadas"][t-1]
    let capsQTieneQHaberVisto=this.traerTodosLosCapitulosQTieneQHaberVistoPorTemporada(t)

     let ultimoCapTemporada=capsQTieneQHaberVisto[capsQTieneQHaberVisto.length-1]
     if(this.progreso.c==ultimoCapTemporada.i){
      if(this.progreso.porcentaje>97){
        return 1;
        }
      }else if(this.progreso.c>ultimoCapTemporada.i)   return 1;

      return 0;

  }

  getIsActiveSurvey(is_active:any,cb: Function){

    let url = this.urlServer + "/getIsActiveSurvey";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      is_active: is_active

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }

  deleteSurvey(id:any,cb: Function){

    let url = this.urlServer + "/deleteSurvey";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }

  deleteNotice(id:any,cb: Function){

    let url = this.urlServer + "/deleteNotice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }

  deleteCategorie(id:any,cb: Function){

    let url = this.urlServer + "/delete-categorie";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }

  deleteUploadNotice(id:any,cb: Function){

    let url = this.urlServer + "/deleteUploadNotice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }

  editNotice(id:any,cb: Function){

    let url = this.urlServer + "/editNotice";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }


  editCategorie(id:any,cb: Function){

    let url = this.urlServer + "/editCategorie";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      id: id

    }

    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
    .then(res => cb(res))
    .catch(err =>  cb(err));

  }


  addSurvey(titulo: string,date_start: string,date_end: string,embed: string,is_active: any,list_occupation_id:any ,country:any,cb: Function){
    let url = this.urlServer + "/createSurvey";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());

    let data = {
      title: titulo,
      date_start: date_start,
      date_end: date_end,
      survey: embed,
      is_active: is_active,
      list_occupation_id: list_occupation_id,
      country_id:country
    }


    this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
     .then(res => cb(res))
     .catch(err =>  cb(err));


  }

  addUserConfirm( name: string,lastname: string,birthday: string,password: string,confirm_password: string,
    country: string,city: string,listOccupation: string,gender: string,hash: string, rest: string,cb: Function ) {
    let url = this.urlServer + "/confirm-user";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    // console.log(headers);

    let data = {
      name: name,
      lastname: lastname,
      birthday: birthday,
      password: password,
      confirm_password: confirm_password,
      country:country,
      city:city,
      listOccupation:listOccupation,
      gender:gender,
      rest:rest,
      hash: hash,
    }



     let result = this.http.post(url, JSON.stringify(data), { headers: headers,  responseType: 'json' }).toPromise()
     .then(res => cb(res))
     .catch(err =>  cb(err));
  }

  addEmployee(name: string,lastname: string,email: string ) {
    let url = this.urlServer + "/addEmployee";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    // console.log(headers);
    let data = {
      username: email,
      name: name,
      lastname: lastname,
      email: email,
      password: '9J{E]{a*+q\m^Ev~',
      confirm_password: '9J{E]{a*+q\m^Ev~',
      role: "employee"
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise();
  }


  addOwner(name: string,lastname: string, email: string ) {
    let url = this.urlServer + "/register";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    let data = {
      username: email,
      name:name,
      lastname: lastname,
      email: email,
      password: 'Dwu{Pk57].ga9xS%',
      confirm_password: 'Dwu{Pk57].ga9xS%',
      role: "owner"
    }
    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise();
  }

  agregarEmpleado(username: string, password: string, email: string) {
    let url = this.urlServer + "/addEmployee";
    let headers = (new HttpHeaders()).append('Content-Type', 'application/json').append("Authorization", "Bearer " + this.token());
    // console.log(headers);


    let data = {
      username: username,
      password: password,
      email: email,
      role: "employee"
    }

    // console.log(data)


    return this.http.post(url, JSON.stringify(data), { headers: headers, observe: "response" }).toPromise()
  }

  abrirAlerta(texto:string){
    document.getElementById("texto-alerta").innerHTML=texto;

    document.getElementById("app-alerta").style.display="block";
    }

    uploadDataUsers(myFormData) {

      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      headers.append("Authorization", "Bearer " + this.token())

      this.http.post(this.urlServer + "/uploadDataUsers", myFormData, {
      headers: headers
      }).subscribe(data => {
       //Check success message
       //sweetalert message popup
       this.route.navigate(["/colaboradores"]);
      return alert(data);
      });

    }



}

