import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service';
import { ModalManager } from 'ngb-modal';

@Component({
  selector: 'app-noticesdetail',
  templateUrl: './noticesdetail.component.html',
  styleUrls: ['./noticesdetail.component.scss']
})
export class NoticesdetailComponent implements OnInit {
  @ViewChild('myModalImage', {static: true}) myModalImage;
  @ViewChild('myModalVideo', {static: true}) myModalVideo;
  @ViewChild('myModalAudio', {static: true}) myModalAudio;
  id:any;

  public notice:any[]=[];
  public uploadsNotice:any[]=[];
  public uploadsNoticeVoice:any[]=[];
  private modalRef; 
  title:any;
  description:any;
  text_banner:any;
  link:any;
  url_notice:any;
  homeSlider ={items: 1, dots: true, nav: true};

  img:any=1;
  video:any=1;
  voice:any=1;
  uploadsurl_voice:any;
  

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public serv: ServiService, 
    private modalService: ModalManager
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.url_notice = this.serv.urlImg;
    console.log(this.id);
    this.serv.getDetailNotice(this.id).then(e=>{
      let obj:any = e; 
      console.log(e);
      this.notice = obj.body.notice; 
      this.title = obj.body.notice.title; 
      this.text_banner = obj.body.notice.text_banner; 
      this.description = obj.body.notice.description; 
      this.uploadsNotice = obj.body.uploadsFiles;
      console.log(this.uploadsNotice);
      this.uploadsNoticeVoice = obj.body.uploadsVoice;

      this.link =  obj.body.notice.link; 

     /* for (let key in this.uploadsNotice) {

        if(this.uploadsNotice[key].url_image === null){
          this.img = 0;
        }
        if(this.uploadsNotice[key].url_video === null){
          this.video = 0;
        }
        if(this.uploadsNotice[key].url_voice === null){
          this.voice = 0;
        }
       
      }
      */
      console.log(this.notice);
      console.log(this.title);
      console.log(this.description);
      console.log(this.link);
      console.log(this.uploadsNotice);
    }); 
  }



  openModalImage(){
    this.modalRef = this.modalService.open(this.myModalImage, {
      size: "md",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })

  }

  openModalVideo(){
    this.modalRef = this.modalService.open(this.myModalVideo, {
      size: "md",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })

  }

  
  openModalAudio(audio,podcast_id){
    this.serv.savedNoticeView(  this.id,podcast_id,'',this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e; 
      console.log(obj); 
    });

 
    this.uploadsurl_voice = audio;
    this.modalRef = this.modalService.open(this.myModalAudio, {
      size: "md",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })
}

openVideo(video_id){
  this.serv.savedNoticeView(this.id,'',video_id,this.serv.traerDataUsuario().user.id).then(e=>{
    let obj:any = e; 
    console.log(obj); 
  });
}


  closeModal(){
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
  }

  returnKm(){
    this.router.navigate(["/temporadas"]);

  }


}
