import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent implements OnInit {

  public msjPass:string='';
  public msjPassCon:string='';
  public msjPassDis:string='';
  public msjerror:string='';
  public msjsuccess:string='';
  public hash:string='';
  public validatorForm:any;
  public validatorFormPass:any;
  public validatorFormLength:any;


  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
  }

  confirmPass(){
    let password = document.getElementById("pass1")["value"].trim();
    let confirm_password = document.getElementById("pass2")["value"].trim();

   

   if(confirm_password == ''){
      this.msjPassCon = 'La contraseña de confirmación es requerida';
      this.validatorFormPass = 0;
    }else{
      this.msjPassCon = '';
      this.validatorFormPass = 1;
 
    } 

   if( confirm_password.length < 8){
      this.msjPassCon = 'La contraseña de confirmación debe tener minimo 8 caracteres';
      this.validatorFormLength = 0;
    }else{
      this.msjPassCon = '';
      this.validatorFormLength = 1;

    } 

    if(password == '' || confirm_password =='' || password != confirm_password){
      this.msjPassDis = 'La contraseña de confirmación debe ser igual a la contraseña';
      this.validatorForm = 0;
    }else{ 
      this.msjPassDis = '';
      this.validatorForm = 1;

    }
 
    if(this.validatorForm == 1 && this.validatorFormPass == 1 && this.validatorFormLength == 1){  

    this.serv.resetPassConfirm(this.hash, password,confirm_password, (e)=>{             
  
      let obj = e;
      if(e.status == 200){
    
        this.msjsuccess = 'Contraseña restablecida';
        this.router.navigate(['/'], { state: { msj: this.msjsuccess, status: '1' } });
    
      //  this.router.navigate(['/'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'Ops! Ocurrio un error';
        this.router.navigate(['/'], { state: { msj: this.msjerror, status: '0'} });
      } 
     // this.router.navigate(["/colaboradores"])
      });

    }
     
  }

}
