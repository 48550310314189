import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 

@Component({
  selector: 'app-accept-register',
  templateUrl: './accept-register.component.html',
  styleUrls: ['./accept-register.component.scss']
}) 
export class AcceptRegisterComponent implements OnInit {
  public profile:any[]=[];
  public dataUser:any[]=[];
  public msjUsername:string='';
  public msjname:string='';
  public msjRest:string='';
  public lastname:string='';
  public msjPass:string='';
  public msjPassCon:string='';
  public msjPassDis:string='';
  public msjerror:string='';
  public msjsuccess:string='';
  public hash:string='';
  public name:string='';
  public msjlastname:string=''; 
  public email:string='';
  public gender:any = [];
  public listOccupation:any = [];
  public Country:any = [];
  public listRest:any = [];
  public msjPais:string='';
  public msjCiudad:string='';
  public msjGender:string='';
  public msjlistOccupation:string='';
  public validatorForm:any;
  public validatorFormCity:any;
  public validatorFormGender:any;
  public role:any;

  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');


    this.serv.verifyToken(this.hash, (e)=>{ 
      let obj = e;            
    
      if(obj.state != 'success'){
        if(obj.msg == 'account_verified'){
          this.msjerror = 'La cuenta ha sido verificada.'; 
        }else if(obj.msg == 'invalid_token'){
          this.msjerror = 'Token invalido.'; 
        }else{
          this.msjerror = 'Ops, el token ha expirado.'; 
        }
      //  this.router.navigate(['/'], { state: { msj: this.msjerror } });
      }else{
        this.name = obj.profile.name;
        this.lastname = obj.profile.lastName;
        this.email = obj.user.email;
        this.role = obj.roleUser.rol_id;
  
        if(this.role == 2){

          this.serv.getListOccupation( (e)=>{ 
            /*Aparece solamente dueños*/
            this.listOccupation = e.data.splice( 6, 1 ); 
          });

        }else{
          this.serv.getListOccupation( (e)=>{ 
            this.listOccupation = e.data.splice( 0,6 ); 
            this.listOccupation.splice(0,1);
          });
        }

        this.serv.getGender( (e)=>{ 
          this.gender = e.data; 
        }); 
  
        this.serv.getCountrys( (e)=>{  
          this.Country = e.data.splice(1,4);
        });
      }

     // this.router.navigate(["/colaboradores"])
      });

      this.serv.getRestaurant(2, (e)=>{ 
        this.listRest = e.body.data; 
             console.log(this.listRest);
       
      }); 
  }

  addUser(){
   // let username = document.getElementById("username")["value"].trim();
    let name = document.getElementById("name")["value"].trim();
    let lastname = document.getElementById("lastname")["value"].trim();
    let password = document.getElementById("pass1")["value"].trim();
    let rest = document.getElementById("rest")["value"].trim();
    let confirm_password = document.getElementById("pass2")["value"].trim();
    let birthday = document.getElementById("birthday")["value"].trim(); 
    let country = document.getElementById("country")["value"].trim(); 
    let city = document.getElementById("city")["value"].trim(); 
    let listOccupation = document.getElementById("listOccupation")["value"].trim(); 
    let gender = document.getElementById("gender")["value"].trim(); 

    /*if(username == '' || username.length < 3){
      this.msjUsername = 'El nombre de usuario es requerido';
    }else*/ 
    if(name == ''){
      this.msjname = 'El nombre es requerido';
      this.validatorForm = 0;
    }else{
      this.msjname = '';
      this.validatorForm = 1;

    } 

    if(lastname == '' ){
      this.msjlastname = 'El apellido es requerido';
      this.validatorForm = 0;
    }else{
      this.msjlastname = '';
      this.validatorForm = 1;

    }

    if( listOccupation == ''){
      this.msjlistOccupation = 'La ocupación es requerida';
      this.validatorForm = 0;
    }else{
      this.msjlistOccupation = '';
      this.validatorForm = 1;

    } 



    if( country == ''){
      this.msjPais = 'El país es requerido';
      this.validatorForm = 0;
    }else{
      this.msjPais = '';
      this.validatorForm = 1;

    } 



    if(rest== ''){
      this.msjRest = 'El tipo de restaurante';
      this.validatorForm = 0;
    }else{
      this.msjRest = '';
      this.validatorForm = 1;

    } 

    if(password == ''){
      this.msjPass = 'La contraseña es requerida';
      this.validatorForm = 0;
    }else{
      this.msjPass = '';
      this.validatorForm = 1;

    } 

   

    if(password.length < 8){
      this.msjPass = 'La contraseña debe tener minimo 8 caracteres';
      this.validatorForm = 0;
    }else{
      this.msjPass = '';
      this.validatorForm = 1;

    } 

   if(confirm_password == ''){
      this.msjPassCon = 'La contraseña de confirmación es requerida';
      this.validatorForm = 0;
    }else{
      this.msjPassCon = '';
      this.validatorForm = 1;

    } 

   if( confirm_password.length < 8){
      this.msjPassCon = 'La contraseña de confirmación debe tener minimo 8 caracteres';
      this.validatorForm = 0;
    }else{
      this.msjPassCon = '';
      this.validatorForm = 1;

    } 

    if(password == '' || confirm_password =='' || password != confirm_password){
      this.msjPassDis = 'La contraseña de confirmación debe ser igual a la contraseña';
      this.validatorForm = 0;
    }else{
      this.msjPassDis = '';
      this.validatorForm = 1;

    }

    if( gender == ''){
      this.msjGender = 'El género es requerido';
      this.validatorForm = 0;
    }else{
      this.msjGender = '';
      this.validatorFormGender = 1;

    } 

    if( city == ''){
      this.msjCiudad = 'La ciudad es requerida';
      this.validatorForm = 0;
    }else{
      this.msjCiudad = '';
      this.validatorFormCity = 1;

    }

    
     

    if(this.validatorForm == 1 && this.validatorFormGender == 1 && this.validatorFormCity == 1 ){ 

    this.serv.addUserConfirm( name,lastname,birthday,password,
      confirm_password,country,city,listOccupation,gender,this.hash,rest, (e)=>{             

      let obj = e;
      if(obj.state == 'success'){
        this.serv.login(this.email, password, (e)=>{
          if(e === 'error'){
            this.msjerror = 'Ops, ocurrio un error.';
            this.router.navigate(['/'], { state: { msj: this.msjerror, status: '0'} });
          }else{
           this.router.navigate(["/colaboradores"]);
          }
     //  
         });
      //  this.router.navigate(['/'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'Ops, ocurrio un error.';
        this.router.navigate(['/'], { state: { msj: this.msjerror, status: '0'} });
      } 
     // this.router.navigate(["/colaboradores"])
      });

    }
    

    
 
    }

    filterRest(id){
       
      
      this.serv.getRestaurant(id, (e)=>{ 
        this.listRest = e.body.data; 
             console.log(this.listRest.length);
             if(this.listRest.length == 0) {
              document.getElementById('type_rest').style.display = "none";
              } else {
              document.getElementById('type_rest').style.display = "block";
              }
       
      }); 

 

    }

}
