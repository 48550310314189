import { Component, OnInit } from '@angular/core';
import { ServiService } from 'src/app/servicios/servi.service';
import { ReprovideoComponent } from '../reprovideo/reprovideo.component';
@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.scss']
})
export class PreguntasComponent implements OnInit {
public preguntas=[];
public dataUser:any[]=[]; 
public user_id:string = '';
public pregunta:any;



  constructor(public serv:ServiService,public reproductor: ReprovideoComponent) {
    window["preg"]=this
    this.preguntas=window["temporadas"][this.serv.viendoTemporada-1]["preguntas"]
    console.log(this.preguntas)
    /*  for(let i=0;i<this.preguntas.length;i++){
        for(let j=0;j<this.preguntas[i].respuestas.length;j++){
          //mezclo las respuestas
          //la correcta es siempre la primera q viene del json
          let rta=this.preguntas[i].respuestas[j];
        }
      }*/
   }

  ngOnInit() { 
 
    
  }
  sendTest(){ 
      let correct= true;
      for(let i=0;i<this.preguntas.length;i++){
         this.pregunta =(<HTMLInputElement>document.querySelector('input[name="'+'rta'+i+'"]:checked')).value;
        if(this.pregunta!=window["temporadas"][this.serv.viendoTemporada-1]["preguntas"][i].correcta){
          correct=false;
        }
      }
  if(correct){
    this.serv.saveQuestion(this.pregunta, (e)=>{ 

    });

    this.reproductor.respondioTest();  
  }else{
    this.serv.saveQuestion(this.pregunta, (e)=>{ 


    }); 
    
    alert("Las respuestas no son correctas");
  }
 
  
  }
  changevideo(){

 
  }

}
