import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SafeHtml } from "@angular/platform-browser";
import { DomSanitizer } from "@angular/platform-browser";
import { ServiService } from "../../servicios/servi.service";
import { ModalManager } from "ngb-modal";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-colaboradores",
  templateUrl: "./colaboradores.component.html",
  styleUrls: ["./colaboradores.component.scss"],
})
export class ColaboradoresComponent implements OnInit {
  @ViewChild("myModal", { static: true }) myModal;
  @ViewChild("ModalUpload", { static: true }) ModalUpload;
  public colaboradores: any[] = [];
  public dataUser: any[] = [];
  public msjToken: string = "";
  public status: string = "";
  public rolUser: string = "";
  public survey: any = [];
  public hash: string = "";
  public sesionHash: string = "";
  public sessionBottler: string = "";
  public url_user: string = "";
  html: SafeHtml;
  public occupation: any;
  public nameUser: any;
  public lastNameUser: any;
  public viendoTemp: any;
  public img_user: any;
  public url_img_user: any;
  public cant_colaboradores: any;
  public name_colaboradores: any;
  public id_colaboradores: any = 0;
  public mail_user: any = 0;
  public delete_user: any = 0;
  public nameUserDelete: any;
  public msjerror: string = "";
  public msjsuccess: string = "";
  filedata: any;
  private modalRef;
  public data_user_load: any;
  public bottler: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public serv: ServiService,
    private sanitizer: DomSanitizer,
    private modalService: ModalManager
  ) {
    if (typeof this.router.getCurrentNavigation().extras.state != "undefined") {
      this.msjToken = this.router.getCurrentNavigation().extras.state.msj;
      this.status = this.router.getCurrentNavigation().extras.state.status;
    }
  }

  fileEvent(e) {
    this.filedata = e.target.files[0];
  }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get("hash");
    console.log(this.hash);

    console.log(this.data_user_load);

    this.hash = this.route.snapshot.paramMap.get("hash");

    if (this.hash != null) {
      console.log("paso hash true");
      localStorage.clear();
      let urlhash = this.hash.split("$");
      this.bottler = urlhash[1];
      this.hash = urlhash[0];
      localStorage["bottler"] = this.bottler;
      localStorage["hash"] = this.hash;
      //  if(this.hash != null && typeof localStorage.hash != 'undefined'){

      // }

      this.sesionHash = localStorage.hash;
      this.sessionBottler = localStorage.bottler;

      this.serv.generateLogin(this.hash, (e) => {
        let obj = e;
        this.serv.loginUrl(obj.user.username, obj.user.password, (e) => {
          if (e === "error") {
            this.router.navigate(["/"]);
          } else {
            console.log("paso a colaboradores con hash");
            // this.router.navigate(["/colaboradores/" + this.hash]);

            // this.bottler =this.serv.traerDataUsuario().user.bottler.toUpperCase().trim();
            //  location.reload();

            this.serv.getSurvey((e) => {
              this.survey = "";

              for (let i in e.data) {
                if (e.data[i].is_active == 1) {
                  this.survey = this.sanitizer.bypassSecurityTrustHtml(
                    e.data[i].survey
                  );

                  this.openModal();
                }
              }
            });

            this.url_user = this.serv.traerDataUsuario().user.url_user;

            window["col"] = this;
            console.log(this.url_user)
            this.colaboradores = this.serv.traerColaboradores();
            this.cant_colaboradores =
              this.colaboradores == null ? 0 : this.colaboradores.length;
            console.log(this.colaboradores);


            this.rolUser = this.serv.traerDataUsuario().user.role;

          }
          //
          
        });
      });
    } else {
      console.log(this.serv.traerDataUsuario().user);

      this.dataUser = this.serv.traerDataUsuario();
      this.viendoTemp = this.serv.viendoTemporada;

      if (typeof this.serv.traerDataUsuario().user != "undefined") {
        console.log("usuario indefinido");
        this.data_user_load = this.serv
          .getProfileUserLoad(this.serv.traerDataUsuario().user.id)
          .then((e) => {
            let obj: any = e;

            var temporal = JSON.parse(localStorage.getItem("usuario"));
            var cola = obj.body.user.collaborators;

            if (cola != null && cola.length > 0) {
              for (let i = 0; i < cola.length; i++) {
                // if(cola[i].user.id==this.id_colaboradores){
                //   cola.splice(i,1);
                cola[i].user.status = cola[i].user.status;
                //  break;
                //   }
              }

              temporal.user = obj.body.user;
              this.colaboradores = cola;
              this.cant_colaboradores = this.colaboradores.length;
              localStorage.setItem("usuario", JSON.stringify(temporal));
            }
          });

        this.nameUser = this.serv.traerDataUsuario().user.profiles.name;
        this.lastNameUser = this.serv.traerDataUsuario().user.profiles.lastName;
        this.url_user = this.serv.traerDataUsuario().user.url_user;
        this.url_img_user =
          this.serv.urlImg + "/" + this.serv.traerDataUsuario().user.img_user;
        this.img_user = this.serv.traerDataUsuario().user.img_user;
        // console.log('abajo bottler');
        // this.bottler =this.serv.traerDataUsuario().user.bottler.toUpperCase().trim();
        // console.log(this.bottler+' bottler validation');
      }

      if (localStorage.hash != null) {
        this.sesionHash = localStorage.hash;
      }

      if (localStorage.bottler != null) {
        this.sessionBottler = localStorage.bottler;
      }

      this.serv.getSurvey((e) => {
        this.survey = "";

        for (let i in e.data) {
          if (e.data[i].is_active == 1) {
            this.survey = this.sanitizer.bypassSecurityTrustHtml(
              e.data[i].survey
            );

            this.openModal();
          }
        }
      });

      window["col"] = this;
      this.colaboradores = this.serv.traerColaboradores();
      this.cant_colaboradores =
        this.colaboradores == null ? 0 : this.colaboradores.length;
      console.log(this.colaboradores);

      this.rolUser = this.serv.traerDataUsuario().user.role;
    }
  }

  logout() {
    this.serv.logout();
    this.router.navigate(["/login"]);
  }

  urlUser(url_user) {
    document.location.href = url_user;
  }

  openModal() {
    this.modalRef = this.modalService.open(this.myModal, {
      size: "md",
      modalClass: "mymodal",
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop",
    });
  }

  openModalDeleteUser(name, id) {
    this.name_colaboradores = name;
    this.id_colaboradores = id;
    this.delete_user = 1;
    let name_user = name.charAt(0).toUpperCase() + name.slice(1);

    this.survey = "¿Está seguro de eliminar al usuario " + name_user + "?";
    this.modalRef = this.modalService.open(this.myModal, {
      size: "md",
      modalClass: "mymodal",
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop",
    });
  }

  openModalSendMailUser(name, id) {
    this.name_colaboradores = name;
    this.id_colaboradores = id;
    this.mail_user = 1;
    let name_user = name.charAt(0).toUpperCase() + name.slice(1);

    this.survey =
      "¿Está seguro de enviar correo de registro al usuario " + name_user + "?";
    this.modalRef = this.modalService.open(this.myModal, {
      size: "md",
      modalClass: "mymodal",
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop",
    });
  }

  async deleteUser() {
    let _this = this;

    console.log(this.id_colaboradores);
    const state = await this.serv
      .deleteUser(this.id_colaboradores)
      .then((e) => {
        let obj: any = e;
        return obj.body.state;
      });

    if (state == "success") {
      var temporal = JSON.parse(localStorage.getItem("usuario"));
      var cola = temporal.user.collaborators;
      for (let i = 0; i < cola.length; i++) {
        if (cola[i].user.id == this.id_colaboradores) {
          cola.splice(i, 1);
          break;
        }
      }
      temporal.user.collaborators = cola;
      this.colaboradores = cola;
      this.cant_colaboradores = this.colaboradores.length;
      localStorage.setItem("usuario", JSON.stringify(temporal));

      this.msjsuccess = "¡Usuario eliminado!";

      this.router.onSameUrlNavigation = "reload";
      // this.router.navigate(["/colaboradores"], {
      //   state: { msj: this.msjsuccess, status: "1" },
      // });
    } else {
      this.msjerror = "Ops, ocurrio un error.";
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = "reload";
      // this.router.navigate(["/colaboradores"], {
      //   state: { msj: this.msjerror, status: "0" },
      // });
    }

    this.closeModal();
  }

  closeModal() {
    this.id_colaboradores = 0;
    this.survey = "";
    this.delete_user = 0;
    this.mail_user = 0;
    this.modalService.close(this.modalRef);

    //or this.modalRef.close();
  }

  users() {
    console.log(this.rolUser, "myrol");
    if (this.rolUser == "root") {
      this.router.navigate(["/usuarios"]);
    } else {
      alert("No tiene privilegios para realizar esta acción");
    }
  }

  addUser() {
    console.log(this.rolUser, "myrol");
    if (this.rolUser == "root") {
      this.router.navigate(["/registro-owner"]);
    } else if (this.rolUser == "owner") {
      this.router.navigate(["/registro-empleado"]);
    } else {
      alert("No tiene privilegios para realizar esta acción");
    }
  }

  addSurvey() {
    if (this.rolUser == "root") {
      this.router.navigate(["/encuestas"]);
    } else {
      alert("No tiene privilegios para realizar esta acción");
    }
  }

  addNotices() {
    if (this.rolUser == "root") {
      this.router.navigate(["/noticias-admin"]);
    } else {
      alert("No tiene privilegios para realizar esta acción");
    }
  }

  addCategories() {
    if (this.rolUser == "root") {
      this.router.navigate(["/categorias-admin"]);
    } else {
      alert("No tiene privilegios para realizar esta acción");
    }
  }

  OldsendMailUser(id_col) {
    this.serv.sendMailUser(id_col, this.serv.traerDataUsuario().user.id);
  }

  async sendMailUser() {
    let _this = this;

    console.log(this.id_colaboradores);
    const state = await this.serv
      .sendMailUser(this.id_colaboradores, this.serv.traerDataUsuario().user.id)
      .then((e) => {
        let obj: any = e;
        console.log(obj.body);
        return obj.body.state + "-" + obj.body.data;
      });
    let status = state.split("-");
    if (status[0] == "success") {
      var temporal = JSON.parse(localStorage.getItem("usuario"));
      var cola = temporal.user.collaborators;
      for (let i = 0; i < cola.length; i++) {
        if (cola[i].user.id == this.id_colaboradores) {
          //   cola.splice(i,1);
          cola[i].user.status = status[1];
          console.log(cola);
          break;
        }
      }
      temporal.user.collaborators = cola;
      this.colaboradores = cola;
      this.cant_colaboradores = this.colaboradores.length;
      localStorage.setItem("usuario", JSON.stringify(temporal));

      this.msjsuccess = "¡Correo de registro enviado!";

      this.router.onSameUrlNavigation = "reload";
      // this.router.navigate(["/colaboradores"], {
      //   state: { msj: this.msjsuccess, status: "1" },
      // });
    } else {
      this.msjerror = "Ops, ocurrio un error.";
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = "reload";
      // this.router.navigate(["/colaboradores"], {
      //   state: { msj: this.msjerror, status: "0" },
      // });
    }

    this.closeModal();
  }

  agregarEmpleado() {
    let username = prompt("username");
    if (!username) return;
    let password = prompt("password");
    if (!password) return;
    let email = prompt("email");
    if (!email) return;

    this.serv
      .agregarEmpleado(username, password, email)
      .then((e) => {
        alert("se agrego el usuario " + username);
      })
      .catch((e) => {
        alert("algo malió sal");
      });
  }

  agregarOwner() {
    /*  console.log("agregar")
    let username=prompt("username")
    if(!username) return
    let password=prompt("password")
    if(!password) return
    let email=prompt("email")
    if(!email) return
    this.serv.agregarOwner(username, password, email).then(e=>{
      alert("se agrego el usuario "+username)
    }).catch(e=>{
      alert("algo malió sal")
    })*/
  }

  maxTemporada(cola: any) {
    let max = 0;
    for (let i = 0; i < cola.user.profiles.seasons.length; i++) {
      if (parseInt(cola.user.profiles.seasons[i].code) > max)
        max = parseInt(cola.user.profiles.seasons[i].code);
    }
    return max;
  }

  entrarComoCola() {
    //  this.serv.establecerColaboradorActivo(k);
    this.router.navigate(["/temporadas"]);
  }

  onSubmitform(f: NgForm) {
    console.log(this.serv.token());
    var myFormData = new FormData();

    myFormData.append("document", this.filedata, this.filedata.name);
    myFormData.append("userid", this.serv.traerDataUsuario().user.id);

    this.serv.uploadDataUsers(myFormData);
    this.closeModal();
  }

  openModalUploadUsers() {
    this.modalRef = this.modalService.open(this.ModalUpload, {
      size: "md",
      modalClass: "ModalUpload",
      hideCloseButton: false,
      centered: false,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop",
    });
  }

  returnKm() {
    // this.router.navigate(["/colaboradores"]);
  }
}
