import { Component, OnInit } from "@angular/core";
//import { ServiService } from '../../servicios/servi.service';
//import { Router } from "@angular/router";
import { ReprovideoComponent } from '../reprovideo/reprovideo.component';

@Component({
  selector: "app-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.scss"]
})
export class QuizComponent implements OnInit {
  public pregunta: string = "";
  public capsula: number = 0;
  public respuestas: any[] = [];
  public videoError: string = "";
  public videoActual: string = "";
  public loading: boolean[] = [];

  private playerTime: number = 0;

  constructor(public reproductor: ReprovideoComponent) {}

  ngOnInit() {
    window["quiz"] = this;
    this.playerTime = this.reproductor.currentTime;
    let capitulo = this.reproductor.capituloActual;    
    this.capsula = capitulo.preguntas.capsula;
    this.pregunta = capitulo.preguntas.pregunta;
    let respuestas = capitulo.preguntas.respuestas.map((p, index) => {
      return {
        respuesta: p,
        numero: index
      }; 
    });
    this.respuestas = this.shuffle(respuestas);
    this.videoError = capitulo.preguntas.video_error;
    this.videoActual = capitulo.videoURL;
  }


  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  answer(k: boolean){
    

    if(k){
      this.reproductor.continuarConCapitulo(this.playerTime);     
    }else{ 
      this.reproductor.cargarVideoError(this.videoError, this.playerTime);
    }

  }
}
