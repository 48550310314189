import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 
import { ModalManager } from 'ngb-modal'; 
import { FormGroup, FormControl, Validators} from '@angular/forms';

enum CheckBoxType { SI, NO, NONE };

@Component({
  selector: 'app-notices-admin',
  templateUrl: './notices-admin.component.html',
  styleUrls: ['./notices-admin.component.scss']
})
export class NoticesAdminComponent implements OnInit {
  @ViewChild('myModal', {static: true}) myModal;
  @ViewChild('myModalFormEdit', {static: true}) myModalFormEdit;

  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType;
  
  private modalRef; 
  public notice:any = [];
  public Country:any = [];
  filedataphoto:any;
  filedatavideo:any;
  filedataaudio:any;
  validRequired:any;
  public categorie:any = [];
  public msjTitulo:string='';
  public msjDescription:string='';
  public msjCountry:string='';
  images = [];
  videos = [];
  voices = [];
  public msjsuccess:string='';
  public msjerror:string='';
  p: number = 1;

  isChecked;
  isCheckedName;
  public checkboxData = [0,1];


  imagesEdit = [];
  videosEdit = [];
  voicesEdit = [];
  url_notice:any;
/*
  id :any; 
  description:any;
  link:any;
  date_init:any;
  date_end:any;
  country_id:any;*/ 

  checkboxes = [{
    name: 'Si',
    value: '1'
}, {
    name: 'No',
    value: '0'
}];
  myForm = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl('', [Validators.required, Validators.minLength(3)]),
    text_banner: new FormControl('', [Validators.required, Validators.minLength(3)]),
    filephoto: new FormControl(''),
    uploadFilesUrlImage: new FormControl(''),
    filevideos: new FormControl(''),
    uploadFilesUrlVideo: new FormControl(''),
    filevoices: new FormControl(''),
    uploadFilesUrlVoice: new FormControl(''),
    link: new FormControl(''),
    date_init: new FormControl(''),
    date_end: new FormControl(''),
    country: new FormControl(''),
    category_id: new FormControl(''),
    outstanding: new FormControl('', [Validators.required]),
    id: new FormControl(''),
  });

  myFormEdit = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl('', [Validators.required, Validators.minLength(3)]),
    text_banner: new FormControl('', [Validators.required, Validators.minLength(3)]),
    filephoto: new FormControl(''),
    uploadFilesUrlImage: new FormControl(''),
    filevideos: new FormControl(''),
    uploadFilesUrlVideo: new FormControl(''),
    filevoices: new FormControl(''),
    uploadFilesUrlVoice: new FormControl(''),
    link: new FormControl(''),
    date_init: new FormControl(''),
    date_end: new FormControl(''),
    country: new FormControl(''),
    category_id: new FormControl(''),
    outstanding: new FormControl(''), 
    id: new FormControl(''),
  });       

  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService,private modalService: ModalManager) { }
    get f(){
      return this.myForm.controls;
    }

    onFileChangeImage(event) {
      if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
                  var reader = new FileReader();
     
                  reader.onload = (event:any) => {
                    console.log(event.target.result);
                     this.images.push(event.target.result); 
     
                     this.myForm.patchValue({
                      uploadFilesUrlImage: this.images
                     });
                  }
    
                  reader.readAsDataURL(event.target.files[i]);
          }
      }
    }

     deleteImage(url: any) {
      this.images = this.images.filter((a) => a !== url);
  }

  onFileChangeEditImage(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
   
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.images.push(event.target.result); 
   
                   this.myFormEdit.patchValue({
                    uploadFilesUrlImage: this.images
                   });
                }
  
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }

  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }

    onFileChangeVideo(event) {
      if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
                  var reader = new FileReader();
     
                  reader.onload = (event:any) => {
                    console.log(event.target.result);
                     this.videos.push(event.target.result); 
     
                     this.myForm.patchValue({
                      uploadFilesUrlVideo: this.videos
                     });
                  }
    
                  reader.readAsDataURL(event.target.files[i]);
          }
      }
    }

     deleteVideo(url: any) {
      this.videos = this.videos.filter((a) => a !== url);
  }

  onChange(e){       
    this.isChecked = !this.isChecked;
    this.isCheckedName = e.target.name;
  }
  onFileChangeEditVideo(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
   
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.videos.push(event.target.result); 
   
                   this.myFormEdit.patchValue({
                    uploadFilesUrlVideo: this.videos
                   });
                }
  
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }

    onFileChangeVoices(event) {
      if (event.target.files && event.target.files[0]) {
          var filesAmount = event.target.files.length;
          for (let i = 0; i < filesAmount; i++) {
                  var reader = new FileReader();
     
                  reader.onload = (event:any) => {
                    console.log(event.target.result);
                     this.voices.push(event.target.result); 
     
                     this.myForm.patchValue({
                      uploadFilesUrlVoice: this.voices
                     });
                  }
    
                  reader.readAsDataURL(event.target.files[i]);
          }
      }
    }

     deleteVoice(url: any) {
      this.voices = this.voices.filter((a) => a !== url);
  }


  onFileChangeEditVoices(event) {
    if (event.target.files && event.target.files[0]) {
        var filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
   
                reader.onload = (event:any) => {
                  console.log(event.target.result);
                   this.voices.push(event.target.result); 
   
                   this.myFormEdit.patchValue({
                    uploadFilesUrlVoice: this.voices
                   });
                }
  
                reader.readAsDataURL(event.target.files[i]);
        }
    }
  }



    fileEventPhoto(e){
      this.filedataphoto = e.target.files[0];
   }
   fileEventVideo(e){
    this.filedatavideo = e.target.files[0];
   }
   fileEventAudio(e){
    this.filedataaudio = e.target.files[0];
   }

  ngOnInit() {
    this.serv.getCategories( (e)=>{  
      this.categorie = e.data;
    });

    this.serv.getNotice( (e)=>{  
      this.notice = e.data;
    });
    
    this.serv.getCountrys( (e)=>{  
      this.Country = e.data;
    });

    this.url_notice = this.serv.urlImg;
  }

  openModalNotice(){
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })

  }

  closeModal(){
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
  }

  deleteNotice(id){

    this.serv.deleteNotice( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){
        this.msjsuccess = 'Noticia eliminada exitosamente';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'Ops, ocurrio un error.';
       this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      } 
      console.log(this.msjerror);
     // this.router.navigate(["/colaboradores"])
      });


  }

  editNotice(id){

    this.serv.editNotice( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){ 

        this.myFormEdit.get('id').setValue(obj.notice.id);
        this.myFormEdit.get('title').setValue(obj.notice.title);
        this.myFormEdit.get('description').setValue(obj.notice.description);
        this.myFormEdit.get('text_banner').setValue(obj.notice.text_banner);
       
        if (obj.notice.link != null) {
        this.myFormEdit.get('link').setValue(obj.notice.link);
        }
        if (obj.notice.date_init != null) {
         let date_init = this.formatDate(obj.notice.date_init);
        this.myFormEdit.get('date_init').setValue(date_init);
        }
        if (obj.notice.date_end != null) {
          let date_end = this.formatDate(obj.notice.date_end);
        this.myFormEdit.get('date_end').setValue(date_end);
        }
        if (obj.notice.country_id != null) {
          this.myFormEdit.get('country').setValue(obj.notice.country_id);
        }
        if (obj.notice.category_id != null) {
          this.myFormEdit.get('category_id').setValue(obj.notice.category_id);
        }
        if (obj.notice.outstanding != null) { 
          this.myFormEdit.get('outstanding').setValue(obj.notice.outstanding);
        }

        this.imagesEdit = obj.uploadsImage;
        console.log(this.imagesEdit);
        this.videosEdit = obj.uploadsVideos;
        console.log(this.videosEdit);
        this.voicesEdit = obj.uploadsVoice;
        console.log(this.voicesEdit);

        this.modalRef = this.modalService.open(this.myModalFormEdit, {
          size: "lg",
          modalClass: 'modalNotify',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true,
          backdropClass: "modal-backdrop"
      })
      }
      });
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
  
  submit(){
    console.log(this.myForm.value);
    
        this.serv.savedNotice(this.myForm.value); 
        this.modalService.close(this.modalRef);
       // alert('Noticia actualizada'); 
      
  }

  submitEdit(){
    console.log(this.myFormEdit.value);
    
     let notice = this.serv.updatedNotice(this.myFormEdit.value);  
     this.modalService.close(this.modalRef);
         
     console.log(notice);
      
  }

  deleteUploadEdit(upl,id,url){
    console.log(id);
    

    
    this.serv.deleteUploadNotice( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){
        if(upl== 'img'){
          this.modalService.close(this.modalRef);
          alert('Imagen eliminada exitosamente');
          this.imagesEdit = this.imagesEdit.filter((a) => a !== url);
        }

        if(upl== 'vid'){
          this.modalService.close(this.modalRef);
          alert('Video eliminado exitosamente');
          this.videosEdit = this.videosEdit.filter((a) => a !== url);
        }

        if(upl== 'vce'){
          this.modalService.close(this.modalRef);
          alert('Audio eliminado exitosamente');
          this.voicesEdit = this.voicesEdit.filter((a) => a !== url);
        }

      }else{
        this.modalService.close(this.modalRef);
        this.msjerror = 'Ops, ocurrio un error.';
     //  this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      } 
      console.log(this.msjerror);
     // this.router.navigate(["/colaboradores"])
      });


  }
/*
  onSubmitform(f: NgForm) { 
    this.closeModal(); 
    var myFormData = new FormData();
  
      
    myFormData.append('uploadFilesUrlImage', this.filedataphoto);
    myFormData.append('uploadFilesUrlVideo', this.filedatavideo);
    myFormData.append('uploadFilesUrlVoice', this.filedataaudio);
    
    myFormData.append('userid', this.serv.traerDataUsuario().user.id);

    myFormData.get('uploadFilesUrlVideo');
    console.log(myFormData.get('uploadFilesUrlImage')); 
    console.log(myFormData.get('uploadFilesUrlVideo')); 
    console.log(myFormData.get('uploadFilesUrlVoice')); 
    
   console.log(myFormData.getAll); 
  
  }*/

  returnKm(){
    this.router.navigate(["/temporadas"]);

  }

}
