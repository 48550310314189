import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ServiService} from '../../servicios/servi.service';
import { LimittextPipe } from '../../pipe/limittext.pipe';

@Component({
  selector: 'app-temporadas',
  templateUrl: './temporadas.component.html',
  styleUrls: ['./temporadas.component.scss']
})
export class TemporadasComponent implements OnInit {
  public msjToken:string = '';
  public status:string = ''; 
  public sesionHash:string = '';
  public url_user:string = '';
  public occupation:any; 
  public notice:any[]=[];
  public data_user_load:any;
  public url_notice:any;

  public sessionBottler: string = "";
  homeSlider ={items: 1, dots: true, nav: true};
  dataclass = ["brick celeste","brick blanco","brick gris"];

  constructor(  public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { 
      if(typeof this.router.getCurrentNavigation().extras.state != "undefined"){
        this.msjToken =this.router.getCurrentNavigation().extras.state.msj;
        this.status =this.router.getCurrentNavigation().extras.state.status;
        this.serv.generateCertificate(this.serv.traerDataUsuario().user.id,this.serv.traerDataUsuario().user.profiles.name,this.serv.traerDataUsuario().user.profiles.lastName,(e)=>{             
          let obj = e;
          console.log(e)
         });
     
      } 

    }

  ngOnInit() { 
    this.url_notice = this.serv.urlImg;
    this.serv.getListNoticeHome( (e)=>{   
      let j=0;
      console.log(e.data);
      for(let i in e.data) {
       if(j == this.dataclass.length){
        j = 0;
      }
      console.log(this.dataclass[j]);
      this.notice.push({
         id:e.data[i].id,
         title: e.data[i].title,
         url_image:e.data[i].url_image,
         description: e.data[i].description,
         clasess: this.dataclass[j]
      });
      j++;
     }
      console.log(this.notice);
    }); 

    this.data_user_load = this.serv.getProfileUserLoad(this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e; 
    

      var temporal = JSON.parse(localStorage.getItem('usuario'));
      var cola = obj.body.user.collaborators;
      
      if(cola != null && cola.length > 0 ){ 
      for(let i=0;i<cola.length;i++){
       // if(cola[i].user.id==this.id_colaboradores){
       //   cola.splice(i,1);
       cola[i].user.status = cola[i].user.status; 
        //  break;
     //   }
      }
      
      temporal.user.collaborators = cola; 
      localStorage.setItem('usuario',JSON.stringify(temporal));
    }


      });

    this.url_user = this.serv.traerDataUsuario().user.url_user;   
    if(typeof localStorage.hash != 'undefined'){
      this.sesionHash =  localStorage.hash;
    }
    if (typeof localStorage.bottler != null) {
      this.sessionBottler = localStorage.bottler;
    }

    if(this.serv.progreso.t == 6){
      this.serv.progreso.t == 5;
    }
    window["te"]=this  
  }
  descripcionTemporada(t:number, ){
    return window["temporadas"][t-1].descripcion
    }
  nombreCap(t:number, c:number){
 
    return window["temporadas"][t-1].capitulos[c-1].titulo
    }
  nombreTemporada(k:number){
    return window["temporadas"][k-1].nombre
    }

  verTemporada(x:any){
    
    if(!this.serv.vioTodaLaTemporadaSegunSuRol(x-1)) {
      alert("Recuerda que debes terminar cada temporada para acceder a las siguientes")
      return
    }

    this.serv.establecerTemporada(x);

    this.router.navigate(["/player"])
    }

  
    logout(){
      this.serv.logout();
      this.router.navigate(["/login"]);
      }
      listNotices(id){
        this.serv.savedNoticeView(id,'','',this.serv.traerDataUsuario().user.id).then(e=>{
          let obj:any = e; 
          console.log(obj); 
        });

        this.router.navigate(["/noticias-detalle",id]);
        //this.router.navigate(["/noticias"])
  
      }

      urlUser(url_user){
        document.location.href = url_user;
      }
}
