import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ServiService} from '../../servicios/servi.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public msj:string = '';
  public msjToken:string = ''; 
  public status:string = ''; 
  public viendoTemp: any;

  constructor(  public route: ActivatedRoute,
    public router: Router, public serv:ServiService,private metaService: Meta) {
     // this.router.getCurrentNavigation().extras.state; 
    if(typeof this.router.getCurrentNavigation().extras.state != "undefined"){
      this.msjToken =this.router.getCurrentNavigation().extras.state.msj;
      this.status =this.router.getCurrentNavigation().extras.state.status;

    } 
        

   }

  ngOnInit() {
    if (localStorage["recordame"] != "true") {
         localStorage.clear()
      }
  this.viendoTemp =  this.serv.viendoTemporada;
  if(this.viendoTemp == 1){ 
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_cobre.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );
  }else if(this.viendoTemp == 2){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_bronce.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else if(this.viendoTemp == 3){

    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_plata.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );
  }else if(this.viendoTemp == 4){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_oro.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else if(this.viendoTemp == 5){
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_platino.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }else{
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/imagenes/medallas/medalla_platino.png';

    this.metaService.addTag( { property: 'og:image', content: imageUrl } );

  }
  //  this.msj = this.route.snapshot.paramMap.get('msj');
  } 

  recordame(val:any){
    let v=val.target.checked;
    localStorage["recordame"]=v;
  }
  daleLogin(){
     this.serv.login(document.getElementById("userLogin")["value"], document.getElementById("passLogin")["value"], (e)=>{
       if(e === 'error'){
        this.msj = 'Usuario o clave invalidos';
       }else{
        this.router.navigate(["/colaboradores"]);
       }
  //  
      });

   // this.serv.guardarToken("blablabal token de prueba 56h46h67h575g644")

   // this.router.navigate(["/colaboradores"])
  }


  resetPassword(){
    this.router.navigate(["/reset-passwords"]);
  }

  ngAfterViewInit(){
    if(localStorage["recordame"]=="true"){
      document.getElementById("merecordaras")["checked"]=true;
      }
    }
}
