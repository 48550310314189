import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {VgCoreModule} from 'videogular2/compiled/core';
import {VgControlsModule} from 'videogular2/compiled/controls';
import {VgOverlayPlayModule} from 'videogular2/compiled/overlay-play';
import {VgBufferingModule} from 'videogular2/compiled/buffering';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './compos/login/login.component';
import { TemporadasComponent } from './compos/temporadas/temporadas.component';
import { ReprovideoComponent } from './compos/reprovideo/reprovideo.component';
import { MenuComponent } from './compos/menu/menu.component';
import { ColaboradoresComponent } from './compos/colaboradores/colaboradores.component';
import { HttpClientModule }    from '@angular/common/http';
import { RegistroComponent } from './compos/registro/registro.component';
import { PreguntasComponent } from './compos/preguntas/preguntas.component';
import { RegistroEmpleadoComponent } from './compos/registro-empleado/registro-empleado.component';
import { AlertaComponent } from './compos/alerta/alerta.component';
import { QuizComponent } from './compos/quiz/quiz.component';
import { AcceptRegisterComponent } from './compos/accept-register/accept-register.component';
import { FbLikeComponent } from './compos/fb-like/fb-like.component';
import { EncuestaComponent } from './compos/encuesta/encuesta.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngb-modal';
import { GeppComponent } from './compos/gepp/gepp.component';
import { ResetPasswordComponent } from './compos/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './compos/reset-password-confirm/reset-password-confirm.component';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { UsersComponent } from './compos/users/users.component';
import { DataTablesModule } from 'angular-datatables';
import { NoticesAdminComponent } from './compos/notices-admin/notices-admin.component';
import { NoticesComponent } from './compos/notices/notices.component';
import { NoticesdetailComponent } from './compos/noticesdetail/noticesdetail.component';
import { LimittextPipe } from './pipe/limittext.pipe';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { CategoriesComponent } from './compos/categories/categories.component';
import { OwlModule } from 'ngx-owl-carousel';
import {NgxPaginationModule} from 'ngx-pagination'; 
 
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TemporadasComponent,
    ReprovideoComponent,
    MenuComponent,
    ColaboradoresComponent,
    RegistroComponent,
    PreguntasComponent,
    RegistroEmpleadoComponent,
    AlertaComponent, 
    QuizComponent,
    AcceptRegisterComponent,
    FbLikeComponent,
    EncuestaComponent,
    GeppComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmComponent,
    UsersComponent,
    NoticesAdminComponent,
    NoticesComponent,
    NoticesdetailComponent,
    LimittextPipe,
    CategoriesComponent,
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    HttpClientModule,
    ModalModule,
    DataTablesModule,
    FormsModule,
    NgxUsefulSwiperModule, 
    ReactiveFormsModule,
    OwlModule,
    NgxPaginationModule
    
    
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
