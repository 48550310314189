import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public msj:string='';
  public msjsuccess:string='';
  public msjerror:string='';


  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { }

  ngOnInit() {
  }

  resetPassword(){
    let email = document.getElementById("email")["value"].trim();
    if(email == '' || email.length < 3){
      this.msj = 'El email es requerido';
    } else{
      this.msj = '';  
      this.serv.resetPassword(email, (e)=>{    
       
      if(e.status == 200){ 
        this.msjsuccess = 'Se ha enviado un mail de recuperación de contraseña';
        this.router.navigate(['/'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'El email no se encuentra registrado';
        this.router.navigate(['/'], { state: { msj: this.msjerror, status: '0'} });
      } 
      });

    }
    

  }

}
