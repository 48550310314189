import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReprovideoComponent } from './compos/reprovideo/reprovideo.component';
import { LoginComponent } from './compos/login/login.component';
import { TemporadasComponent } from './compos/temporadas/temporadas.component';
import { ColaboradoresComponent } from './compos/colaboradores/colaboradores.component';
import { RegistroComponent } from './compos/registro/registro.component';
import { RegistroEmpleadoComponent } from './compos/registro-empleado/registro-empleado.component';
import { AcceptRegisterComponent } from './compos/accept-register/accept-register.component';
import { EncuestaComponent } from './compos/encuesta/encuesta.component';
import { GeppComponent } from './compos/gepp/gepp.component';
import { ResetPasswordComponent } from './compos/reset-password/reset-password.component';
import { ResetPasswordConfirmComponent } from './compos/reset-password-confirm/reset-password-confirm.component';
import { UsersComponent } from './compos/users/users.component';
import { NoticesAdminComponent } from './compos/notices-admin/notices-admin.component';
import { NoticesComponent } from './compos/notices/notices.component';
import { NoticesdetailComponent } from './compos/noticesdetail/noticesdetail.component';
import { CategoriesComponent } from './compos/categories/categories.component';


const routes: Routes = [
 { path: '', component: LoginComponent },
 { path: 'player', component: ReprovideoComponent },
 { path: 'login', component: LoginComponent },
 { path: 'temporadas', component: TemporadasComponent },
 { path: 'colaboradores', component: ColaboradoresComponent },
 { path: 'colaboradores-home/:hash', component: ColaboradoresComponent },
 { path: 'registro-owner', component: RegistroComponent },
 { path: 'registro-empleado', component: RegistroEmpleadoComponent },
 { path: 'registro-empleado/:hash', component: AcceptRegisterComponent },
 { path: 'registro-owner/:hash', component: AcceptRegisterComponent },
 { path: 'registro-owner-gp', component: AcceptRegisterComponent },
 { path: 'encuestas', component: EncuestaComponent },
 { path: 'gepp/:hash',  component: GeppComponent },
 { path: 'gepp',  component: GeppComponent },
 { path: 'reset-pass-confirm/:hash',  component: ResetPasswordConfirmComponent },
 { path: 'reset-passwords',  component: ResetPasswordComponent },
 { path: 'usuarios',  component: UsersComponent },
 { path: 'noticias-admin',  component: NoticesAdminComponent },
 { path: 'noticias',  component: NoticesComponent },
 { path: 'noticias-detalle/:id',  component: NoticesdetailComponent },
 { path: 'categorias-admin',  component: CategoriesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
