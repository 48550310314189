import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {
 
  public msjTitulo:string='';
  public msjembed:string='';
  public msjis_active:string='';
  public msjListOc:string='';
  public msjCountry:string='';
  public msjsuccess:string='';
  public msjerror:string='';
  public validatorForm=1;
  public listOccupation:any = [];
  public survey:any = [];
  public Country:any = [];
  public City:any = [];
  


  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { }

  ngOnInit() {
    this.serv.getListOccupation( (e)=>{ 
      this.listOccupation = e.data; 
    });


    this.serv.getSurvey( (e)=>{  
      this.survey = e.data;
    });
    
    this.serv.getCountrys( (e)=>{  
      this.Country = e.data;
    });

    this.serv.getCitys( (e)=>{  
      this.City = e.data;
    });

  }

  deleteSurvey(id){

    this.serv.deleteSurvey( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){
        this.msjsuccess = 'Encuesta eliminada exitosamente';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'Ops, ocurrio un error.';
       this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      } 
      console.log(this.msjerror);
     // this.router.navigate(["/colaboradores"])
      });

    }
   

  addSurvey(){ 
     let titulo = document.getElementById("titulo")["value"].trim();
     let date_start = document.getElementById("date_start")["value"].trim();
     let date_end = document.getElementById("date_end")["value"].trim();
     let embed = document.getElementById("embed")["value"].trim();
     let list_occupation_id = document.getElementById("listOccupation")["value"].trim(); 
     let country = document.getElementById("country")["value"].trim();
     let is_active = document.getElementsByName("is_active"); 
  


    for (var i = 0, length = is_active.length; i < length; i++) {
      if (is_active[i]["checked"]) { 
        is_active = is_active[i]["value"]; 
        break;
      }
    }
 
  
     if(titulo == ''){
       this.msjTitulo = 'El titulo es requerido';
       this.validatorForm = 0;
     }else{
      this.msjTitulo = '';
      this.validatorForm = 1;
     }
     
     if(embed == '' ){
       this.msjembed = 'El embed es requerido';
       this.validatorForm = 0;
     }else{
      this.msjembed = '';
      this.validatorForm = 1;
     } 

    if(list_occupation_id == '' ){
      this.msjListOc = 'Seleccione ocupación del restaurant';
      this.validatorForm = 0;
    }else{
     this.msjListOc = '';
     this.validatorForm = 1;
    } 

    if(country == '' ){
      this.msjCountry = 'Seleccione pais';
      this.validatorForm = 0;
    }else{
     this.msjCountry = '';
     this.validatorForm = 1;
    } 

    this.validatorForm = 0;
    this.serv.getIsActiveSurvey(is_active,(e)=>{
      let obj = e;
      if(obj.state == 'success'){
        this.validatorForm = 1;
        this.msjis_active = '';
        if(this.validatorForm == 1){
    
          this.serv.addSurvey( titulo,date_start,date_end,embed,is_active,list_occupation_id,country, (e)=>{             

            let obj = e;
      
            if(obj.state == 'success'){
              this.msjsuccess = 'Encuesta creada exitosamente';
              this.router.navigate(['/colaboradores'], { state: { msj: this.msjsuccess, status: '1' } });
            }else{
              this.msjerror = 'Ops, ocurrio un error.';
             this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
            } 

           // this.router.navigate(["/colaboradores"])
            });
      
          }
      }else{
        this.validatorForm = 0;
        this.msjis_active = 'Ya existe una encuesta activa';
      }
 

    });





  } 
  
  }


