import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  public profile:any[]=[];
  public dataUser:any[]=[];
  public msjname:string='';
  public msjlastname:string='';
  public msjemail:string=''; 
  public msjsuccess:string='';
  public msjerror:string=''; 
  public colaboradores:any[]=[];
  public cant_colaboradores:any;


  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService) { }
 
  ngOnInit() {
    this.colaboradores=this.serv.traerColaboradores(); 
    this.cant_colaboradores = this.colaboradores.length;
    let user =  this.serv.traerDataUsuario();
    if(this.cant_colaboradores >= 2 &&  user.user.role!='root'){
    this.msjerror = 'Exedió el limite de usuarios agregados';
    this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });

   }

  }

  async addUser(){
    let name = document.getElementById("name")["value"].trim();
    let lastname = document.getElementById("lastname")["value"].trim();
    let email = document.getElementById("email")["value"].trim(); 

    if(name == '' || name.length < 3){
      this.msjname = 'El nombre es requerido';
    }else if(lastname == ''){
      this.msjlastname = 'El apellido es requerido';
    }else if(email == ''){
      this.msjemail = 'El correo es requerido';
    }else{
      this.msjname = '';
      this.msjlastname = '';
      this.msjemail = ''; 
    const estado:any = await this.serv.addOwner(name,lastname ,email).then(e=>{  
      let obj:any = e;
      return obj.body;

    }).catch((error) => {
      console.log("objeto",error);
   return error;
    });
      console.log("este estado",estado);  
      if(estado.state && estado.state == 'success'){
        let user =  this.serv.traerDataUsuario();
        user.user.collaborators = estado.data;
        localStorage.setItem('usuario',JSON.stringify(user));
        console.log("user",user);
        this.msjsuccess = 'Se ha completado el registro exitosamente';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjsuccess, status: '1' } });
      }else if(estado.error){
        this.msjerror = 'El email ya se encuentra registrado';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      } else{
        this.msjerror = 'Ha ocurrido un error registrando al usuario';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      }
    }
    

    
 
    }

}
