import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiService } from 'src/app/servicios/servi.service';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import {NgForm} from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {Location} from '@angular/common';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild('myModal', {static: true}) myModal;
  @ViewChild('modalNotify', {static: true}) modalNotify;
  public sesionHash:string = '';
  public occupation:any;
  public nameUser:any;
  public lastNameUser:any;
  public viendoTemp:any;
  private modalRef;
  private modalRefNotify;
  public noticeDetail:any=0;
  public img_user:any;
  public url_img_user:any;
  public notice:any = [];
  public notice_length:any;
  public notice_length_saved:any;
  public cant_notice_notify:any=0;

  filedata:any;
 //  public urlServer = "http://localhost/api3km/public/api/v1";
   public urlServer = "https://api.3kmpepsi.com/api/v1";
   //    public urlServer = "https://api-3kmpepsi-com.wspuat.com/api/v1";

  constructor(public serv:ServiService, public router:Router,
    private location: Location,private modalService: ModalManager,public http:HttpClient) {


     }
    fileEvent(e){
      this.filedata = e.target.files[0];
  }

  ocultarMenu(){
    window["$"]("app-menu #menuDesplegable").toggleClass("visible");
    }
  ngOnInit() {

     this.serv.getNotify(this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e;
      this.cant_notice_notify=  obj.body.data.cant_notice_notify;
  });

  this.serv.getNotice( (e)=>{
    this.notice = e.data;
    this.notice_length_saved = e.data.length;
    this.notice_length = e.data.length;
  });



    if(typeof localStorage.hash != 'undefined'){
      this.sesionHash =  localStorage.hash;
    }
    console.log(this.serv.traerDataUsuario().occupationUser+ ' list ocupation');
    if(typeof this.serv.traerDataUsuario().occupationUser != 'undefined'){
      if(this.serv.traerDataUsuario().occupationUser == null || this.serv.traerDataUsuario().occupationUser == 'null' || this.serv.traerDataUsuario().occupationUser == ''){
        this.occupation = '';
      }else{
        this.occupation = this.serv.traerDataUsuario().occupationUser.occupations;


      }
    }else{
      this.occupation = '';

    }
   this.viendoTemp = this.serv.progreso.t;
    this.nameUser = this.serv.traerDataUsuario().user.profiles.name;
    this.nameUser = this.nameUser.charAt(0).toUpperCase() + this.nameUser.slice(1);
    this.lastNameUser = this.serv.traerDataUsuario().user.profiles.lastName;
    this.lastNameUser = this.lastNameUser.charAt(0).toUpperCase().slice(1);
    this.url_img_user = this.serv.urlImg+'/'+this.serv.traerDataUsuario().user.img_user;
    this.img_user = this.serv.traerDataUsuario().user.img_user;


    console.log(this.router.url);
    let str = this.router.url;
  /*
    let url = str.split('/');
   if(url[1]=='noticias-detalle'){
    console.log('sisa');
     this.noticeDetail == 1;
     var element = document.getElementById("myDIV");

     element.classList.add("not-noticias-detalle");
     document.getElementById('pelement').innerHTML='Conoce todas las novedades';

     var elementancla = document.getElementById("ancla-i");
     elementancla.classList.remove("fa-bell");
     elementancla.classList.add("fa-caret-right");


   }else{
    console.log('nolsa');
    var element = document.getElementById("myDIV");
    element.classList.add("not-noticias-detalle");
    element.classList.remove("not-noticias-detalle");
    document.getElementById('pelement').innerHTML='¿Qué hay<br> de nuevo?';
    var elementancla = document.getElementById("ancla-i");
    elementancla.classList.add("fa-caret-right");
    elementancla.classList.remove("fa-caret-right");
    elementancla.classList.add("fa-bell");

     this.noticeDetail == 0;
   }*/


  }


  clickLogo(){
    this.location.back();
  }

  logout(){
    this.serv.logout();
    this.router.navigate(["/login"])
    }

    listNotices(){
      this.router.navigate(["/noticias"])

    }


    formuReg(){
      this.router.navigate(["/registro"])
    }
  mostrarMenu(){

      window["$"]("app-menu #menuDesplegable").toggleClass("visible");
  }

  mostrarMenuNotify(cant_notice_notify){
    this.serv.notifySaved(cant_notice_notify,this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e;
      console.log(obj);
    });
    this.cant_notice_notify = cant_notice_notify;
    this.router.navigate(["/noticias"])
  //  window["$"]("app-menu #menuDesplegableNotify").toggleClass("visible");
}


  openModal(){
    this.modalRef = this.modalService.open(this.myModal, {
        size: "md",
        modalClass: 'mymodal',
        hideCloseButton: false,
        centered: false,
        backdrop: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: "modal-backdrop"
    })
}


openNotify(){
  this.modalRefNotify = this.modalService.open(this.modalNotify, {
      size: "lg",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })
}

onSubmitform(f: NgForm) {
  this.ocultarMenu();
  this.closeModal();
  console.log(this.serv.token());
  var myFormData = new FormData();


  myFormData.append('image', this.filedata);
  myFormData.append('userid', this.serv.traerDataUsuario().user.id);
console.log(myFormData)
 //this.serv.uploadImageProfile(myFormData);

}

closeModal(){
  this.modalService.close(this.modalRef);
  //or this.modalRef.close();
}

closeNotify(){
  this.modalService.close(this.modalRefNotify);
  //or this.modalRef.close();
}

}
