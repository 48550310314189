import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiService } from '../../servicios/servi.service'; 
import { ModalManager } from 'ngb-modal'; 
import { FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @ViewChild('myModal', {static: true}) myModal;
  @ViewChild('myModalFormEdit', {static: true}) myModalFormEdit;
  
  private modalRef; 
  public categorie:any = [];
  public Country:any = [];
 
  public msjTitulo:string='';
  public msjDescription:string='';
  public msjCountry:string='';
 
  public msjsuccess:string='';
  public msjerror:string='';
 
/*
  id :any; 
  description:any;
  link:any;
  date_init:any;
  date_end:any;
  country_id:any;*/ 

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)])
  });

  myFormEdit = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    id: new FormControl(''),
  });

  constructor(public route: ActivatedRoute,
    public router: Router, public serv:ServiService,private modalService: ModalManager) { }
    get f(){
      return this.myForm.controls;
    }



  ngOnInit() {
    this.serv.getCategories( (e)=>{  
      this.categorie = e.data;
    });
    
   
 
  }

  openModalCategorie(){
    this.modalRef = this.modalService.open(this.myModal, {
      size: "lg",
      modalClass: 'modalNotify',
      hideCloseButton: false,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
  })

  }

  closeModal(){
    this.modalService.close(this.modalRef);
    //or this.modalRef.close();
  }

  deleteCategorie(id){

    this.serv.deleteCategorie( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){
        this.msjsuccess = 'Categoria eliminada exitosamente';
        this.router.navigate(['/colaboradores'], { state: { msj: this.msjsuccess, status: '1' } });
      }else{
        this.msjerror = 'Ops, ocurrio un error.';
       this.router.navigate(['/colaboradores'], { state: { msj: this.msjerror, status: '0'} });
      } 
      console.log(this.msjerror);
     // this.router.navigate(["/colaboradores"])
      });


  }

  editCategorie(id){

    this.serv.editCategorie( id, (e)=>{ 

      let obj = e;

      if(obj.state == 'success'){ 

        this.myFormEdit.get('id').setValue(obj.categorie.id);
        this.myFormEdit.get('name').setValue(obj.categorie.name); 

        this.modalRef = this.modalService.open(this.myModalFormEdit, {
          size: "lg",
          modalClass: 'modalNotify',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true,
          backdropClass: "modal-backdrop"
      })


      }
 
      });
  }
 
  
  submit(){
    console.log(this.myForm.value);
    
        this.serv.savedCategorie(this.myForm.value); 
        this.modalService.close(this.modalRef);
      
      
  }

  submitEdit(){
    console.log(this.myFormEdit.value);
    
     let categorie = this.serv.updatedCategorie(this.myFormEdit.value);  
     this.modalService.close(this.modalRef);
         
     console.log(categorie);
      
  }
 

  returnKm(){
    this.router.navigate(["/temporadas"]);

  }


}
