import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons'; 

@Component({
    selector: 'fb-like',
    template: `<div class="fb-like" [attr.data-href]="'http://3km.wiperagency.com/'" data-layout="button_count" data-action="like" data-show-faces="false" data-share="true" data-like="false"></div>`
})

export class FbLikeComponent implements OnInit {
   // @Input() url = location.href;

    constructor() {
        // initialise facebook sdk after it loads if required
        if (!window['fbAsyncInit']) {
            window['fbAsyncInit'] = function () {
                window['FB'].init({ 
                    appId: 'your-app-id',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v3.0'
                });
            };  
        }

        // load facebook sdk if required
        const url = 'https://connect.facebook.net/en_US/sdk.js';
        if (!document.querySelector(`script[src='${url}']`)) {
            let script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        }
    }

    ngOnInit() {
        // render facebook button 
        window['FB'] && window['FB'].XFBML.parse();
    }
}