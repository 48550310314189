import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gepp',
  templateUrl: './gepp.component.html',
  styleUrls: ['./gepp.component.scss']
})
export class GeppComponent implements OnInit {
  public hash:string=''; 

  constructor(public route: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    if(this.hash != null){
      this.router.navigate(["/colaboradores/"+this.hash]);
    }else{
      this.router.navigate(["/registro-owner-gp/"]);
    }
  }

}
