console.log("TEMPORADAS");

/// LA RESPUESTA ORRECTA SIEMPRE Q SEA LA PRIMERA DEL ARRAY DE RESPUESTAS
window["temporadas"] = [{
        nombre: "Temporada 1: Nuestras Raices",
        descripcion: "Don Chopi y María se presentan, nos hablan de Chopis, la importancia de las fuentes de tráfico y su conocimiento.",
        materiales: [
            //   { title: "", capitulo: 0, src: "./assets/Descargables/Temporada 1/Cápsula 1/1- KM Ejercicio de Fuerzas y Oportunidades.pptx" },
            //   { title: "", capitulo: 0, src: "./assets/Descargables/Temporada 1/Cápsula 1/1- KM Encuesta a Consumidor.xlsx" },
            //    { title: "", src: "./assets/Descargables/Temporada 1/Cápsula 1/1- KM Entendiendo a la Competencia.xlsx" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 1/Capsula 1/Características Generales de clientes.xlsx | Características Generales de clientes" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 1/Capsula 1/Ejercicio de Fuerzas y Oportunidades.pptx | Ejercicio de Fuerzas y Oportunidades" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 1/Capsula 1/Encuesta a Consumidor.xlsx | Encuesta a Consumidor" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 1/Capsula 1/Entendiendo a la Competencia.xlsx | Entendiendo a la Competencia" },
            { title: "", capitulo: 2, src: "/assets/Descargables/Temporada 1/Capsula 2/Grilla de Fuentes de Trafico.pptx | Grilla de Fuentes de Trafico" },
            { title: "", capitulo: 2, src: "/assets/Descargables/Temporada 1/Capsula 2/Lista Fuentes de Trafico por área.xlsx | Lista Fuentes de Trafico por área" },
            { title: "", capitulo: 2, src: "/assets/Descargables/Temporada 1/Capsula 2/Oportunidades de Alianza.xlsx | Oportunidades de Alianza" },

        ],
        mostrarmaterial:[
            {capitulo:0,tiempo:200}
        ],
        preguntas: [
            { correcta: 0, pregunta: "Son los factores Internos y externos del ambiente que debes de considerar para el análisis.", respuestas: ["Fortalezas, debilidades, oportunidades y amenazas.", "Clientes, competencia, economía y medio ambiente.", "Carencias, riesgos y ventajas."] },
            { correcta: 0, pregunta: "¿Cuáles son las tres formas de vender?", respuestas: ["Directa, indirecta y cooperando", "En el restaurante, domicilios y para llevar.", "Al contado y al crédito."] },
        ],
        capitulos: [{
                thumb: "assets/imgtemporadas/0.jpg",
                i: 0,
                duracion: 260,
                titulo: "Capítulo 0: Don Chopi nos abre sus puertas",
                videoURL: "s1e1.mp4",
                nombre: "s1e1",
                conceptos: ["1", "2 ", "3", "4"],
                //preguntas: { "tiempo": 5, capsula: 4, correcta: 0, pregunta: "Como se llama el curso?", respuestas: ["KM", "2KM", "1KM"], "video_error": "videoerror.mp4" },
                thumb: "assets/imgtemporadas/0.jpg"

            },
            { thumb: "assets/imgtemporadas/1.jpg", i: 1, duracion: 511, titulo: "Capítulo 1: El poder de conocernos", nombre: "t1e2", videoURL: "s1e2.mp4", conceptos: ["1ghgh", "asouh gdh ", "gg", "jfdj"] },
            { thumb: "assets/imgtemporadas/2.jpg", i: 2, duracion: 334, titulo: "Capítulo 2: Todo tiene un porqué", nombre: "t1e3", videoURL: "s1e3.mp4", conceptos: ["1vertvetet", "heth asodj ", "eh6e6h", "etht"] }
        ]
    },
    {
        nombre: "Temporada 2: Eso que todos queremos",
        descripcion: "5 formas de incrementar las ventas. Cómo hacer ofertas, promociones, cupones y un buen plan de lealtad.",
        materiales: [
            { title: "", capitulo: 0, src: "/assets/Descargables/Temporada 2/Capsula 3/Datos de Ventas Mensuales.xlsx | Datos de Ventas Mensuales" },
            { title: "", capitulo: 0, src: "/assets/Descargables/Temporada 2/Capsula 3/Dias y Canales de Trafico.xlsx | Días y Canales de Trafico" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 2/Capsula 4/Ranking Productos Rentables.xlsx | Ranking Productos Rentables" },
            { title: "", capitulo: 2, src: "/assets/Descargables/Temporada 2/Capsula 5/Plan de Lealtald.pptx | Plan de Lealtald" },
        ],
        mostrarmaterial:[
         
        ],
        preguntas: [
            { correcta: 3, pregunta: "¿Cómo se puede incrementar el ticket?", respuestas: ["Agrandando los grupos", "Haciendo que compren más productos", "Venta de productos de mayor valor", "Todas las anteriores"] },
            { correcta: 3, pregunta: "Son formas para que los clientes compren más en el restaurante", respuestas: ["Armar combos", "Menús ejecutivos", "Paquetes", "Todos los anteriores"] },
            {
                correcta: 1,
                pregunta: "Lleva a tus clientes a serte fiel y te da la posibilidad de atraer clientes nuevos, son características de…",
                respuestas: ["La hora feliz", "Plan de lealtad", "Combos al 2X1"]
            }
        ],
        capitulos: [
            { thumb: "assets/imgtemporadas/3.jpg", i: 0, duracion: 525, titulo: "Capítulo 3: Felices de verte", nombre: "t2e1", videoURL: "s2e1.mp4", conceptos: ["2blabal", "asouh asodj ", "asodhasdjo", "jfdj"] },
            {
                thumb: "assets/imgtemporadas/4.jpg",
                i: 1,
                duracion: 382,
                titulo: "Capítulo 4: Las Cuentas claras",
                nombre: "t2e2",
                videoURL: "s2e2.mp4",
                conceptos: ["2ghgh", "asouh gdh ", "gg", "jfdj"],
                preguntas: {
                    capsula: 4,
                    tiempo: 357.4,
                    respuestas: [{
                            correcta: true
                        },
                        {
                            correcta: false
                        },
                        {
                            correcta: false
                        }
                    ],
                    video_error: "capsula4error.mp4"
                }
            },
            {
                thumb: "assets/imgtemporadas/5.jpg",
                i: 2,
                duracion: 479,
                titulo: "Capítulo 5: Todo gran poder conlleva una gran responsabilidad",
                nombre: "t2e3",
                videoURL: "s2e3.mp4",
                conceptos: ["2vertvetet", "heth asodj ", "eh6e6h", "etht"],
                preguntas: {
                    capsula: 5,
                    tiempo: 163,
                    respuestas: [{
                            correcta: true
                        },
                        {
                            correcta: false
                        }
                    ],
                    video_error: "capsula5error.mp4"
                }
            }

        ]
    },
    {
        nombre: "Temporada 3: Un Antes y un Después",
        descripcion: "El paso a paso completo de cómo desarrollar una buena estrategia KM.",
        materiales: [
            { title: "", capitulo: 0, src: "/assets/Descargables/Temporada 3/Capsula 6/Calculador de Objetivos.xlsx | Calculador de Objetivos" },
            { title: "", capitulo: 0, src: "/assets/Descargables/Temporada 3/Capsula 6/Formulario de Caso de Exito.xlsx | Formulario de Caso de Exito" },
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 3/Bonus/Motivacion Empleados.pptx | Motivacion Empleados" },
        ],
        mostrarmaterial:[
         
        ],
        preguntas: [
            { correcta: 0, pregunta: "¿Cuáles son las características de un objetivo SMART?", respuestas: ["Medible, alcanzable, realista y tiempo", "Haciendo que compren más productos", "Claro, alcanzable, razonable y tiempo", "Fortalezas, debilidades, oportunidades y amenazas."] }
        ],
        capitulos: [
            { thumb: "assets/imgtemporadas/6.jpg", i: 0, duracion: 428, titulo: "Capítulo 6: Cada pieza en su lugar", nombre: "t3e1", videoURL: "s3e1.mp4", conceptos: ["3blabal", "asouh asodj ", "asodhasdjo", "jfdj"] },
            { rol: "admin", thumb: "assets/imgtemporadas/bonus.jpg", i: 1, duracion: 360, titulo: "Bonus: Todos somos uno", nombre: "t3e2", videoURL: "bonus.mp4", conceptos: ["3ghgh", "asouh gdh ", "gg", "jfdj"] }

        ]
    },
    {
        nombre: "Temporada 4: La Gran Revelación",
        descripcion: "El mundo digital y el delivery: Redes sociales, Whatsapp Business, Google Mi Negocio, y agregadores.",
        materiales: [
            { title: "", capitulo: 1, src: "/assets/Descargables/Temporada 4/Capsula 8/Paso a Paso Redes Sociales.docx | Paso a Paso Redes Sociales" },
            { title: "", capitulo: 2, src: "/assets/Descargables/Temporada 4/Capsula 9/Paso a Paso Whatsapp Business.docx | Paso a Paso Whatsapp Business" },
            { title: "", capitulo: 3, src: "/assets/Descargables/Temporada 4/Capsula 10/Paso a Paso Google MI NEGOCIO.docx | Paso a Paso Google MI NEGOCIO" },
            { title: "", capitulo: 4, src: "/assets/Descargables/Temporada 4/Capsula 11/Comparativos Agregadores.pptx | Comparativos Agregadores" },
            { title: "", capitulo: 4, src: "/assets/Descargables/Temporada 4/Capsula 11/Paso a paso Agregadores.docx | Paso a paso Agregadores" },
        ],
        mostrarmaterial:[
         
        ],
        preguntas: [{
                correcta: 1,
                pregunta: "¿Cuáles son los 4 micromomentos?",
                respuestas: ["Vender, promocionar, producir y ofrecer.", "Ser visto, ser considerado, ser elegido y ser recordado.", "Ser Anunciado, ser recordado, tener visitas y vender"]
            },
            {
                correcta: 2,
                pregunta: "Menciona las dos claves para atrapar la atención de tus clientes.",
                respuestas: ["Tener muchos likes y buscar seguidores nuevos", "Seguir a influencers y tener más de dos redes sociales.", "Un texto que describa el producto y una imagen donde el producto se vea super rico."]
            },
            {
                correcta: 3,
                pregunta: "Son funcionalidades que ofrece WhatsApp business",
                respuestas: ["Crear respuestas automatizadas.", "Agrupar contactos de clientes.", "Enviar mensajes masivos a tus contactos.", "Todas la anteriores."]
            },
            {
                correcta: 2,
                pregunta: "¿Cuáles son las características de tu negocio que pueden verse en Google mi negocio?",
                respuestas: ["Horas de servicio y número de mesas disponibles.", "Agrupar contactos de clientes.", "Reputación del negocio, horarios, cómo es el local y como pueden contactarse."]
            },
            {
                correcta: 2,
                pregunta: "¿Cuáles las tres fases claves de la entrega?",
                respuestas: ["Planeación, ejecución y seguimiento", "Inicio, Desarrollo y final", "Pedido, Pago y entrega"]
            },
        ],
        capitulos: [
            { thumb: "assets/imgtemporadas/7.jpg", i: 0, duracion: 327, titulo: "Capítulo 7: Don Chopis y el nuevo mundo", nombre: "t4e1", videoURL: "s4e1.mp4", conceptos: ["4blabal", "asouh asodj ", "asodhasdjo", "jfdj"] },
            {
                thumb: "assets/imgtemporadas/8.jpg",
                i: 1,
                duracion: 521,
                titulo: "Capítulo 8: El sabor de lo nuevo",
                nombre: "t4e2",
                videoURL: "s4e2.mp4",
                conceptos: ["4ghgh", "asouh gdh ", "gg", "jfdj"],
                preguntas: {
                    capsula: 8,
                    tiempo: 222,
                    respuestas: [{
                            correcta: false
                        },
                        {
                            correcta: true
                        }
                    ],
                    video_error: "capsula8error.mp4"
                }
            },
            { thumb: "assets/imgtemporadas/9.jpg", i: 2, duracion: 251, titulo: "Capítulo 9: Un mundo de posibilidades Whatsapp Business ", nombre: "t4e3", videoURL: "s4e3.mp4", conceptos: ["4vertvetet", "heth asodj ", "eh6e6h", "etht"] },
            { thumb: "assets/imgtemporadas/10.jpg", i: 3, duracion: 352, titulo: "Capítulo 10: Un mundo de posibilidades ¡Aquí estoy!", nombre: "t4e4", videoURL: "s4e4.mp4", conceptos: ["4stvsttrvrt", "asouh eth ", "5g3 3 3g", "35h"] },
            { thumb: "assets/imgtemporadas/11.jpg", i: 4, duracion: 311, titulo: "Capítulo 11: Chopis donde te encuentres", nombre: "t4e5", videoURL: "s4e5.mp4", conceptos: ["4stvsttrvrt", "asouh eth ", "5g3 3 3g", "35h"] }
        ]
    }, {
        nombre: "Temporada 5: Cuenta conmigo",
        descripcion: "Cómo aprovechar los productos de PepsiCo y su respaldo en pos de tu negocio.",
        materiales: [],
        mostrarmaterial:[
         
        ],
        preguntas: [{
                correcta: 3,
                pregunta: "Son las formas en las que puedes usar los productos de Pepsico Alimentos",
                respuestas: ["Acompañamiento", "Ingrediente", "Impulso", "Todas la anteriores"]
            },
            {
                correcta: 3,
                pregunta: "En qué consiste el Poder de las 3 Ves",
                respuestas: ["Variedad: Es la disponibilidad de portafolio, categorías y segmentos.", "Valor: Tener el producto correcto para cada momento de consumo y cubra lasnecesidades del negocio y consumidor.", "Visibilidad: Son todos los elementos de comunicación que ayudan a la venta.", "Todas la anteriores"]
            }
        ],
        capitulos: [
            { thumb: "assets/imgtemporadas/12.jpg", i: 0, duracion: 57, titulo: "Capítulo 12: Date el gusto", nombre: "t5e1", videoURL: "s5e1.mp4", conceptos: ["4blabal", "asouh asodj ", "asodhasdjo", "jfdj"] },
            { thumb: "assets/imgtemporadas/13.jpg", i: 1, duracion: 12, titulo: "Capítulo 13: El poder de las 3 V", nombre: "t5e2", videoURL: "s5e2.mp4", conceptos: ["4ghgh", "asouh gdh ", "gg", "jfdj"] }


        ]
    },
    {
        nombre: "Temporada 6: Cuenta conmigo",
        descripcion: "Cómo aprovechar los productos de PepsiCo y su respaldo en pos de tu negocio.",
        materiales: [],
        mostrarmaterial:[
         
        ],
        preguntas: [{
                correcta: 3,
                pregunta: "Son las formas en las que puedes usar los productos de Pepsico Alimentos",
                respuestas: ["Acompañamiento", "Ingrediente", "Impulso", "Todas la anteriores"]
            },
            {
                correcta: 2,
                pregunta: "En que consiste el Poder de las 3 Ves",
                respuestas: ["Variedad: Es la disponibilidad de portafolio, categorías y segmentos.", "Valor: Tener el producto correcto para cada momento de consumo y cubra lasnecesidades del negocio y consumidor.", "Visibilidad: Son todos los elementos de comunicación que ayudan a la venta.", "Todas la anteriores"]
            }
        ],
        capitulos: [
            { thumb: "assets/imagenes/img-temporada.jpg", i: 0, duracion: 57, titulo: "Capítulo 12: Pendiente", nombre: "t5e1", videoURL: "s5e1.mp4", conceptos: ["4blabal", "asouh asodj ", "asodhasdjo", "jfdj"] },
            { thumb: "assets/imagenes/img-temporada.jpg", i: 1, duracion: 12, titulo: "Capítulo 13: Pendiente", nombre: "t5e2", videoURL: "s5e2.mp4", conceptos: ["4ghgh", "asouh gdh ", "gg", "jfdj"] }


        ]
    }

]