import { Component, OnInit } from '@angular/core';
import { ServiService } from '../../servicios/servi.service';
import { Router } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { HttpClient } from '@angular/common/http';
import { LimittextPipe } from '../../pipe/limittext.pipe';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss']
})


export class NoticesComponent implements OnInit {
  public notice:any[]=[];
  public type:any=[];
  public projects:any=[];
  public url_notice:any;
  p: number = 1;

  homeSlider = {items:1,dots:true,nav:true};
   
  dataclass = ["brick celeste","brick blanco","brick gris"];
  constructor(public serv:ServiService, public router:Router,) { }

  

  ngOnInit() { 
    this.url_notice = this.serv.urlImg;
    this.serv.getListNotice( (e)=>{   
      let j=0;
      console.log(e.data);
      for(let i in e.data) { 
       if(j == this.dataclass.length){
        j = 0;
      }
      console.log(this.dataclass[j]);
      this.notice.push({
         id:e.data[i].id,
         title: e.data[i].title,
         url_image:e.data[i].url_image,
         description: e.data[i].description,
         clasess: this.dataclass[j]
      });
      j++;
     }
      console.log(this.notice);
    }); 
    
  }

  
  addNotices(id) {
    console.log(id);
    this.serv.savedNoticeView(id,'','',this.serv.traerDataUsuario().user.id).then(e=>{
      let obj:any = e; 
      console.log(obj); 
    });
      this.router.navigate(["/noticias-detalle",id]);
  }

  returnKm(){
    this.router.navigate(["/temporadas"]);

  }


}
